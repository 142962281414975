<template>
  <div class="type">
    <div class="level">
      <div class="level-item">
        <b-field>
          <b-radio-button
            v-model="parentData.typeIdentity"
            v-for="(type, index) in typeList"
            :key="index"
            :native-value="type"
            type="is-success  is-outlined"
           size="is-small"
          >
          <span  @click="changeTypeIdentity(type)">            {{ type }}</span>

          </b-radio-button>
        </b-field>
      </div>
      <div class="level-right">
        <b-button size="is-small" icon-left="plus" type="is-success" @click="edit">添加</b-button> 
      </div>
      <!-- {{ list }} -->
    </div>
    <b-table
      size="is-small"
      striped
      :data="list"
      hoverable
      :debounce-search="1000"
      :loading="isLoading"
    >
      <b-table-column
        v-for="(column, index) in columnsTemplate"
        :key="index"
        :label="column.title"
        v-slot="props"
      >
        <span>{{ props.row[column.label] }}</span>
      </b-table-column>
      <b-table-column label="是否热门" v-slot="props">
        {{ props.row.isHot==1?"是":"否" }}
      </b-table-column>
      <b-table-column label="操作" v-slot="props" width="150">
        <div class="controlPanel">
          <b-button
            size="is-small"
            icon-left="lead-pencil"
            @click="edit(props.row)"
            type="is-info"
          >
          </b-button>

         
          <b-button
            size="is-small"
            icon-left="delete"
            @click="deleteBtn(props.row)"
            type="is-danger"
          >
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <b-sidebar
      
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="isOverlay"
      :right="right"
      v-model="openDrawer"
      :on-cancel="closeDrawer"
    >   
   
      <Article :dataToChildren="parentData" v-if="openDrawer"></Article>
    </b-sidebar>
  </div>
</template>

<script>
import postData from "../../../common/http";
import Article from './article'

export default {
    components:{Article},
  data() {
    return {
      parentData:{
        typeIdentity:"articleType"
      },
      deletePath:"/commonType/delete",
      isLoading:true,
        fullheight:true,
        fullwidth:true,
        isOverlay:true,
        right:"right",
        openDrawer:false,
      list: [],
      columnsTemplate: [
        {
          title: "id",
          label: "id",
        },
        {
          title: "名称",
          label: "name",
        },
        {
          title: "描述",
          label: "desc",
        },

        // {
        //   title: "父节点",
        //   label: "parentId",
        // },
      ],
      typeIdentity: "articleType",
      path: "/commonType/list",
      typeList: ["articleType","tripType","sceneType", "keyword", "tag",  "transportType",],
    };
  },
  methods: {
    change(){
      
    },
    edit(item){
        if(item.id){
          this.parentData=Object.assign(this.parentData,item);
        }else{
          this.parentData={typeIdentity:this.parentData.typeIdentity}
        }
       
      
        // if(item.id)...item,
        this.openDrawer=true;
       
    },
    changeTypeIdentity(type){
      this.parentData.typeIdentity=type;
      this.getList(this.parentData.typeIdentity);
      
    },
    async deleteBtn(item){
      
      let data=await postData(this.deletePath,{
        json:{
          id:item.id
        }
      })
      if (data == null) {
        this.$buefy.toast.open("成功");
        this.getList(this.parentData.typeIdentity)
      }
      
    },
    async getList(type) {
      let list = await postData(this.path, {
        json: {
          typeIdentity: this.parentData.typeIdentity,
        },
      });

      this.list = list.length?list:[];
      this.isLoading=false;
      this.$forceUpdate();
    },
    closeDrawer(){
      
      this.getList(this.parentData.typeIdentity);
     

    }
  },
  created() {
    
    this.getList(this.parentData.typeIdentity);
   
  },
  watch:{
    typeIdentity(){
      
       
    }
    
  }
};
</script>

<style lang="sass" scoped>
.controlPanel{

  display:flex;
  justify-content:space-around;
}
</style>