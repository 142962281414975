<template>
  <div class="newArtcleListContainer">
    <div class="mainContent">
      <div class="mainContentHeader">
        <div class="mainContentHeaderLeft">
          <!-- Hot View -->
          <!-- <hot-view-tab></hot-view-tab> -->
          <!-- Hot View -->
          <!-- Hot Country -->
          <hot-country></hot-country>
          <!-- Hot Country -->
        </div>
        <section class="mainContentHeaderRight">
          <!-- Geo -->
          <hot-geo></hot-geo>
          <!-- Geo -->

          <!-- HotTravel -->
          <!-- <hot-travel></hot-travel> -->
          <!-- HotTravel -->
          <hot-view-tab></hot-view-tab>
        </section>
      </div>
      <div class="mainContentMiddle">
        <common-title common-title-name="Hot Topic" :is-show-more="false"></common-title>
        <div class="mainContentMiddleWrapper">
          <!-- Hot  Topic-->
          <carousel></carousel>
          <!-- Hot  Topic-->

          <!-- Hot Line -->
          <hot-line></hot-line>
          <!-- Hot Line -->
        </div>
      </div>
      <hot-news></hot-news>
      <hot-f-a-q></hot-f-a-q>
    </div>
  </div>
</template>

<script>
import postData from "../../common/cHttp";
import HotViewTab from "./components/hotViewTab.vue"
import HotCountry from "./components/hotCountry.vue";
import HotGeo from "./components/hotGeo.vue";
import HotTravel from "./components/hotTravel.vue";
import CommonTitle from "./components/commonTitle.vue";
import Carousel from "./components/carousel.vue"
import HotLine from "./components/hotLine.vue"
import HotNews from "./components/hotNews.vue";
import HotFAQ from "./components/hotFqa.vue"
export default {
  name: "NewArtcleList",
  components: {
    HotViewTab,
    HotCountry,
    HotGeo,
    HotTravel,
    CommonTitle,
    Carousel,
    HotLine,
    HotNews,
    HotFAQ
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import url("@/assets/css/defalut.scss");
a {
  color: inherit;
  text-decoration: inherit;
}
.newArtcleListContainer {
  width: 100%;
  display: flex;
  .mainContent {
    flex: 1;
    margin-left: 6rem;
    margin-top: 1.2rem;
    .mainContentHeader,.mainContentMiddle,.mainContentNews{
      border:1px solid rgba(33,33,33,.08);
      background:#fff!important;
      border-bottom:transparent;
     
    }
    .mainContentHeader {
      width: 100%;
      display: flex;
      margin-bottom: 0.5rem;
      .mainContentHeaderLeft {
        width: 85%;
        height: 100%;
        // margin-right: 0.5rem;
        display: flex;
        flex-direction: column;
        background:#f7f8fc;
        padding-right:0.5rem;
      }
      .mainContentHeaderRight {
        width: 15%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .mainContentMiddle {
      background:#fff;
      width: 100%;
      .mainContentMiddleWrapper {
        display: flex;
      }
    }
  }
}
</style>
