<template>

  <ul class="hotViewList">
    <li v-for="(item, index) in hotViewList" :key="item.name" @mouseenter="hotViewIndex = index"
      @mouseleave="hotViewIndex = null">
      <a :href="'https://leisuretripinasia.com'+handleLink(item)" target="_blank">
        <img :src="hotViewIndex == index ? item.checkSrc : item.src" alt="">
      </a>
      <a :href="'https://leisuretripinasia.com'+handleLink(item)" target="_blank">
        <h3 class="hotTitle lx-text-14 lg-text-3c3c43">{{ item.name }}</h3>
      </a>
    </li>
  </ul>


</template>

<script>
import { replaceSpacesWithDash } from '@/utils/utils'
export default {
  name: "HotViewTab",
  data() {
    return {
      hotViewIndex: null,
      hotViewList: [
        { name: "Great Wall", id: 11, country: "China", countryId: 285, src: require("@/assets/img/home/great_wall.png"), checkSrc: require("@/assets/img/home/great_wall_active.png") },
        { name: "Taj Mahal", id: 20, country: "India", countryId: 19, src: require("@/assets/img/home/Taj_Mahal.png"), checkSrc: require("@/assets/img/home/Taj_Mahal_active.png") },
        { name: "Angkor Wat", id: 26, country: "Cambodia", countryId: 25, src: require("@/assets/img/home/Angkor_Wat.png"), checkSrc: require("@/assets/img/home/Angkor_Wat_active.png") },
        { name: "Mount Fuji", id: 22, country: "Japan", countryId: 305, src: require("@/assets/img/home/Mount_Fuji.png"), checkSrc: require("@/assets/img/home/Mount_Fuji_active.png") },
        { name: "Bali Island", id: 24, country: "Indonesia", countryId: 18, src: require("@/assets/img/home/Bali_Island.png"), checkSrc: require("@/assets/img/home/Bali_Island_active.png") },
        { name: "Halong Bay", id: 25, country: "Vietnam", countryId: 16, src: require("@/assets/img/home/Halong_Bay.png"), checkSrc: require("@/assets/img/home/Halong_Bay_active.png") },
        { name: "Forbidden City", id: 19, country: "China", countryId: 285, src: require("@/assets/img/home/Forbidden_City.png"), checkSrc: require("@/assets/img/home/Forbidden_City_active.png") },
        { name: "Petronas Twin Tower", id: 23, country: "Malaysia", countryId: 17, src: require("@/assets/img/home/Petronas_TT.png"), checkSrc: require("@/assets/img/home/Petronas_TT_active.png") },
      ],
    }
  },
  methods: {
    handleLink(item) {
      // let path = `/travel-attractions`
      let path = `/travel-attractions/${replaceSpacesWithDash(item.country)}/${replaceSpacesWithDash(item.name)}`
      let query = {
        countryId: item.countryId,
        sceneId: item.id
      }
      let routeData = this.$router.resolve(({
        path: path, // path 要跳转的路由地址
        query: query
      }))
      return routeData.href
    }
  }
}
</script>

<style lang="scss" scoped>
.hotViewList {

  display: flex;
  flex-wrap: wrap;
  /* 允许换行 */
  gap: 0px;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 26rem;
  background: rgba(255, 255, 255, 1);
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;

  .hotTitle {
    text-align: center;
  }

  .hotTitle:hover {
    text-decoration: underline !important;
    ;
  }

  li {
    // background: #ddd;
    min-height: 6rem;
    width: calc(50% - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      margin-top: .5rem;
      width: 2.5rem !important;
      height: 2.5rem !important;
    }

    h3 {
      margin-top: 4px;
    }

    &:hover {
      ::v-deep .mdi-google-photos {
        color: #EFBE25 !important;
      }

      a {

        color: #EFBE25 !important;

        h3 {
          color: #EFBE25 !important;
        }
      }
    }

    a {
      flex: 1;
      color: #222222;

      h2 {
        display: inline-block;
        color: #222222 !important;
      }
    }
  }
}
</style>