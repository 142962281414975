<template>
  <div class="commonTitle">
    <div class="commonTitleLeft" @click="handleClickPage">
      <div class="titleLine"></div>
      <h2 class="mainContentMiddleLeftTitle h2-title">
        <a class="titleWrapper lx-text-20 lx-text-F6CE4F">{{ commonTitleName }}</a>
      </h2>
    </div>
    <div v-if="isShowMore" class="allNews" @click="handleClickPage">
      <div class="more lx-text-16 lx-text-C15950">MORE</div>
      <div class="lArrow">
        <img src="@/assets/img/home/lArrow.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommonTitle",
  props: {
    commonTitleName: {
      type: String,
      default: 'Hot'
    },
    isShowMore: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleClickPage() {
      this.$emit('clickPage')
    }
  }
}
</script>

<style lang="scss">
.commonTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  .commonTitleLeft {
    display: flex;
    align-items: center;
    .titleLine {
      width: 0.5rem;
      height: 1.9rem;
      background: rgba(246, 206, 79, 1);
      margin-right: 0.5rem;
    }
  }
  .allNews {
    display: flex;
    margin-right: 0.5rem;
    cursor: pointer;
    .lArrow {
      width: 0.63rem;
      height: 0.63rem;
      margin-left: 0.5rem;
      margin-top: 0.2rem;
      img {
        width: 100%;
        height: 100%;
        display: inline-block;
      }
    }
  }
}
</style>