<template>
  <div class="mainContentMiddleRight">
    <div class="keywords">
      <ul class="groupList">
        <li v-for="(item, index) in articleGroupTxtList" :key="index" class="groupItem">
          <a class="lx-text-ell-1 lx-text-14 lx-text-555 keyWordsContent" :href="'https://leisuretripinasia.com'+openHotTopic(item)" target="_blank">
            <span class="lx-text-ell-1 lx-text-14 lx-text-3c3c43 verticalSpan">
              {{ item.headline }}</span>
          </a>
        </li>
        <!-- <li class="groupItem" style="display: flex">
          <a
            class="pagenation"
            v-for="(item, index) in 10"
            :key="index"
            :href="
              openHotTopic({
                headline: 'More',
                pageNo: index + 1,
              })
            "
            target="_blank"
            >{{ index + 1 }}</a
          >
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { replaceSpacesWithDash } from "@/utils/utils";
import postData from "../../../common/cHttp";
export default {
  name: "HotLine",
  data() {
    return {
      articlePath: "/api/t/article/homeList",
      pageNo: 1,
      articleGroupTxtList: [
        {
          id: 294,
          headline:
            "Exploring Malacca: A State Rich of Historical Background in Malaysia",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },

        {
          id: 285,
          headline: "Nature Experiences",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },

        {
          id: 278,
          headline:
            "15 Cultural Heritage Sites of Johor Bahru You Should Visit",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
        {
          id: 277,
          headline:
            "Johor: A vibrant city of cultural treasures and family-fun adventures",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
        {
          id: 276,
          headline:
            "Exploring the History and Modern Charm of the Johor Bahru's Thaipusam: From Ancient to Present",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
        {
          id: 275,
          headline: "Eating in George Town, Penang",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
        {
          id: 274,
          headline:
            "Penang Travel Journal : Exotic Historical Charm of Asia – Discover the Timeless Beauty of Malaysia",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
        {
          id: 273,
          headline: "Penang Travel Journal : Exotic Historical Charm From Asia",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
        {
          id: 271,
          headline:
            "Penang’s rich heritage: A journey through time and culture",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
        {
          id: 270,
          headline: "Arts and Culture",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
        {
          id: 269,
          headline: "History of Penang",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
        {
          id: 267,
          headline: "Why I Loved Penang",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink:
              "https://i0.wp.com/www.thiswildlifeofmine.com/wp-content/uploads/2018/01/IMG_1598.jpg?w=2048&ssl=1",
          },
        },
        {
          id: 266,
          headline:
            "What to do in Penang, Malaysia: the island of cultural tapestry",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
        {
          id: 265,
          headline: "The Charm of George Town, Penang",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
        {
          id: 264,
          headline: "Try stopping at one visit: why Penang is irresistible",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
        {
          id: 263,
          headline: "A shimmering Pearl of the Orient",
          status: 4,
          isHot: 1,
          source: {
            url: "",
            mediaType: "1",
            mediaLink: null,
          },
        },
      ],
    };
  },
  created() {
    // this.pageNo=Math.floor(Math.random() * 20) + 1;
    this.getHotLine();
  },
  methods: {
    async getHotLine() {
      let data = await postData(this.articlePath, {
        json: {
          pageNo: 1,
          pageSize: 10,
          articleTypeId: "385",
        },
      });
      let articleTxtList = [];
      if (data && data.records && data.records.length > 0) {
        articleTxtList = data.records.map((item) => {
          let artItem = {
            id: item.id,
            headline: item.headline,
            countryName: item.countryName,
            mediaLink: item.source ? item.source.mediaLink : '',
          };
          return artItem;
        });
        this.articleGroupTxtList = articleTxtList;
      }
    },
    //打开详情页
    openHotTopic(item) {
      let path = "/discover/topic";
      let query = {};
      let routeData = null;
      if (item.headline !== "More") {
        path = `/hot-topic/${replaceSpacesWithDash(item.headline)}`;
        if (item.countryName) {
          path = `/hot-topic/${item.countryName}/${replaceSpacesWithDash(
            item.headline
          )}`;
        }
        query = {
          id: item.id,
          img: encodeURIComponent(item.mediaLink),
        };
        routeData = this.$router.resolve({
          path: path, // path 要跳转的路由地址
          query: query,
        });
      } else {
        query = {
          articleTypeId: "385",
          pageNo: Math.floor(Math.random() * 30) + 1,
        };
        routeData = this.$router.resolve({
          path: path, // path 要跳转的路由地址
          query,
        });
      }

      return routeData.href;
    },

    splitArrayIntoGroups(arr, groupSize) {
      const result = [];
      for (let i = 0; i < arr.length; i += groupSize) {
        const group = arr.slice(i, i + groupSize);
        result.push(group);
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: inherit;
  text-decoration: inherit;
}

.mainContentMiddleRight {
  flex: 1;
  height: 24.9rem;
  background-color: #fff;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-right: 0;
  padding-left: 0.5rem;

  .keywords {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;

    .groupList {
      width: 100%;
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      // &:last-child {
      //   .groupItem {
      //     &:last-child {
      //       box-sizing: border-box;
      //       padding: 0.5rem;
      //       .keyWordsContent {
      //         .verticalSpan {
      //           color: #999 !important;
      //           font-size: 1.33rem !important;
      //           text-align: right;
      //           margin-left: 2rem;
      //         }

      //         &:hover {
      //           .verticalSpan {
      //             color: rgba(229, 88, 0, 1) !important;
      //             text-decoration: underline !important;
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
      .groupItem {
        width: 50%;

        .keyWordsContent {
          display: flex;
          padding-right: 9px;

          &:hover {
            .verticalSpan {
              color: rgba(229, 88, 0, 1) !important;
            }
          }
        }

        .keyWordsContent {
          .verticalSpan {
            padding-right: 6px;
          }

          .verticalSpan:hover {
            text-decoration: underline;
          }
        }

        .pagenation {
          width: 2rem;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          background-color: #f6ce4f;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>