<template>
  <div id="app" class="">
    <Header></Header>
    <tab v-if="isShowTab"></tab>
    <router-view class="main"></router-view>
    <Footer></Footer>
    <Login></Login>
  </div>
</template>

<script>
import Header from "./common/header";
import Tab from "./common/tab";
import Footer from "./common/footer";
import Login from './common/login'
import * as Sentry from "@sentry/browser";
export default {
  name: "App",
  components: {
    Header,
    Tab,
    Footer,
    Login
  },
  data() {
    return {
      isShowTab: false,
    };
  },
  watch: {
    "$route"(to, from) {
      // console.log(to.query.img, from, "app.vue")
      // if (to.query.img) {
      //   let link = document.createElement('link')
      //   link.href = to.query.img;
      //   link.rel="preload";
      //   link.as = "image"
      //   link.fetchpriority = "Highest";
      //   document.body.appendChild(link);
      // }
      if (to.meta && to.meta.tab) {
        this.isShowTab = true;
      } else {
        this.isShowTab = false;
      }
    },
  },
  beforeCreate() {
    this.$store.commit('setUserInfo')
  },
  created() {
    if (this.$route.meta && this.$route.meta.tab) {
      this.isShowTab = true;
    }


    Sentry.init({
      dsn: "https://ebe8e2170ab5e8dc0f3d8f934156bcae@o4505923273621504.ingest.us.sentry.io/4505923310649344",

      integrations: [Sentry.browserTracingIntegration()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  },
};
</script>

<style lang="scss">
html {
  // font-size:.5rem;
  color: rgb(31, 33, 41);
}

#app {
  width: 100%;
  font-size: 12px;
}

.main {
  min-height: 80vh;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  background: #f7f8fc;
}

.b-sidebar .sidebar-content {
  width: 100% !important;
}

a {
  // text-decoration: none;
}

.label,
.field-label {
  font-size: 12px;
}

.navbar.is-spaced {
  padding: 0.1rem !important;
}

html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
  padding-top: 0 !important;
}

h1,
.tit {
  width: 0 !important;
  height: 0 !important;
  display: inline;
  color: #fff;
}

.field-label {
  margin-right: 0.5rem !important;
}

.field-body {
  flex-grow: 14 !important;
}

.hero {
  margin-bottom: 0rem !important;
}

.leftContainer .b-tabs.is-vertical>.tabs ul {
  width: 6vw !important;

  li {
    height: 16vh;
    background: #fff;

    a {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
}

.leftContainer .tabs a {
  width: 4rem !important;
  height: 4rem !important;
  margin: 0 auto !important;
  border-radius: 6px !important;
}

.tabs.is-toggle li.is-active a {
  background: linear-gradient(250deg, #ffd861 -0.65%, #ffcc32) !important;
}

.tabs.is-toggle li a:hover {
  background: #ffcc3266 !important;
}

.tabs.is-toggle a {
  border: none !important;
}

.navbar.is-spaced {
  border-bottom: 1px solid #ddd;
}
</style>
