<template>
  <div class="rightContTopGeo level">
    <div class="level-item has-text-centered">
      <div>
        <!-- <p class="heading">Country</p> -->
        <p class="title">{{ geolocation.countryName?geolocation.countryName:"Reserved" }}</p>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <!-- <p class="heading">city</p> -->
        <p class="title">{{ geolocation.cityName }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ky from "ky";
export default {
  name: "HotGeo",
  data() {
    return {
      geolocation: {}
    }
  },
  mounted() {
    setTimeout(()=>{
      this.getGeolocation();
    },3000)
   
  },
  methods: {
    // 获取当前城市，异步
    getGeolocation() {
      ky.get("http://ip-api.com/json/")
        .then((response) => response.json())
        .then((data) => {
          const { country: countryName, city: cityName, otherProps } = data;
          this.geolocation = Object.assign({}, otherProps, {
            countryName,
            cityName,
          });
        });
    },
  }
}
</script>

<style lang="scss">
.rightContTopGeo {
  background: #fff;
  width: 100%;
  height: 3.75rem;
  margin-bottom:0!important;
  .title {
    min-height: 1.28rem;
    color: #f6ce4f;
    min-width: 1rem;
    
  }
}
</style>