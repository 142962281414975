<template>
  <div>
    <div style="position: relative; margin-bottom: .5rem;">
      <ul class="hotTabList">
        <li
          class="hotTabItem"
          v-for="(item, index) in hotCountryList"
          :key="index"
          @mouseenter="handleEnter(item, index)"
        >
          <a class="hotTabItemTitle" :href="'https://leisuretripinasia.com'+handleChooseCountry(item)" target="_blank">
            <h3 :ref="'tab'+index" class="hotTitle lx-text-16 lx-text-222">{{ item.name }}</h3>
          </a>
        </li>
      </ul>
      <div class="tabLine" :style="{transform: `translateX(${lineOffset}px)`, width: `${lineWidth}px`}"></div>
    </div>
    
    <ul class="hotTabContent">
      <li
        v-for="(item, index) in hotTabContentList"
        :key="index"
        class="hotTabContentItem"
      >
        <ul class="hotTabContentList">
          <li class="hotTabContentTitle">
            <a :href="'https://leisuretripinasia.com'+handleClickTrip(item)">
              <p v-for="(name, nameIndex) in item.name" :key="nameIndex" class="lx-text-14 lg-text-3c3c43">{{ name }}</p>
            </a>
          </li>
          <li v-for="(curr, currIndex) in item.children" :key="currIndex" class="hotTabContentTxt">
            <p class="h3Title">
              <a
                @mouseenter="handleChildEnter(curr, '', item)"
                @mouseleave="handleChildLeave(curr, '', item)"
                class="lx-text-18 lg-text-3c3c43 h3TitleHref"
                :href="'https://leisuretripinasia.com'+handleChooseCity(curr, '', item, false)"
                target="_blank"
              >
                <h3 class="lx-text-16 lg-text-3c3c43">{{ curr.name }}</h3>
              </a>
            </p>
            <p class="h4Title level">
              <a
                v-for="desc in tabDescList"
                :key="desc.id"
                :href="'https://leisuretripinasia.com'+handleChooseCity(curr, desc, item, false)"
                target="_blank"
                @mouseenter="handleChildEnter(curr, desc, item)"
                @mouseleave="handleChildLeave(curr, desc, item)"
                class="level-item has-text-centered h4TitleHref"
              >
                <h4 class="lx-text-14 lx-text-3c3c43c7">{{ desc.descTitle }}</h4>
              </a>
            </p>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { replaceSpacesWithDash } from "@/utils/utils";
import postData from "../../../common/cHttp";
export default {
  name: "HotCountry",
  data() {
    return {
      activeName: null,
      tabActiveIndex: 0,
      hotAllTabObject: {
        null: [
          {
            id: "327",
            name: ["Nature", "Adventure"],
            children: [
              {
                id: 331,
                name: "Zhangjiajie",
                parentId: "285",
              },
              {
                id: 231,
                name: "Jakarta",
                parentId: "18",
              },
              {
                id: 360,
                name: "Tokyo",
                parentId: "305",
              },
              {
                id: "286",
                name: "Seoul",
                parentId: "15",
              },
              {
                id: "228",
                name: "Phang Nga",
                parentId: "14",
              },
              {
                id: "483",
                name: "Halong Bay",
                parentId: "16",
              },
            ],
          },
          {
            id: "326",
            name: ["Culture", "History"],
            children: [
              {
                id: 319,
                name: "Beijing",
                parentId: "285",
              },
              {
                id: 237,
                name: "Yogyakarta",
                parentId: "18",
              },
              {
                id: 361,
                name: "Kyoto",
                parentId: "305",
              },
              {
                id: 383,
                name: "Gyeongju",
                parentId: "15",
              },
              {
                id: 191,
                name: "Ayutthaya",
                parentId: "14",
              },
              {
                id: 412,
                name: "Hue",
                parentId: "16",
              },
            ],
          },
          {
            id: "329",
            name: ["Food",  "Drink"],
            children: [
              {
                id: 322,
                name: "Chengdu",
                parentId: "285",
              },
              {
                id: 230,
                name: "Bali",
                parentId: "18",
              },
              {
                id: 305,
                name: "Osaka",
                parentId: "305",
              },
              {
                id: 286,
                name: "Seoul",
                parentId: "15",
              },
              {
                id: 185,
                name: "Bangkok",
                parentId: "14",
              },
              {
                id: 402,
                name: "Ho Chi Minh",
                parentId: "16",
              },
            ],
          },
          {
            id: "328",
            name: ["Health",  "Wellness"],
            children: [
              {
                id: 330,
                name: "Sanya",
                parentId: "285",
              },
              {
                id: 349,
                name: "Bitung",
                parentId: "18",
              },
              {
                id: 379,
                name: "Hakone",
                parentId: "305",
              },
              {
                id: 381,
                name: "Jeju Island",
                parentId: "15",
              },
              {
                id: 192,
                name: "Chiang Rai",
                parentId: "14",
              },
              {
                id: 407,
                name: "Nha Trang",
                parentId: "16",
              },
            ],
          },
        ],
        285: [
          {
            id: "327",
            name: ["Nature",  "Adventure"],
            children: []
          },
          {
            id: "326",
            name: ["Culture",  "History"],
            children: []
          },
          {
            id: "329",
            name: ["Food",  "Drink"],
            children: []
          },
          {
            id: "328",
            name: ["Health",  "Wellness"],
            children: []
          },
        ],
        18: [
          {
            id: "327",
            name: ["Nature",  "Adventure"],
            children: []
          },
          {
            id: "326",
            name: ["Culture",  "History"],
            children: []
          },
          {
            id: "329",
            name: ["Food",  "Drink"],
            children: []
          },
          {
            id: "328",
            name: ["Health",  "Wellness"],
            children: []
          },
        ],
        305: [
          {
            id: "327",
            name: ["Nature",  "Adventure"],
            children: []
          },
          {
            id: "326",
            name: ["Culture",  "History"],
            children: []
          },
          {
            id: "329",
            name: ["Food",  "Drink"],
            children: []
          },
          {
            id: "328",
            name: ["Health",  "Wellness"],
            children: []
          },
        ],
        15: [
          {
            id: "327",
            name: ["Nature",  "Adventure"],
            children: []
          },
          {
            id: "326",
            name: ["Culture",  "History"],
            children: []
          },
          {
            id: "329",
            name: ["Food",  "Drink"],
            children: []
          },
          {
            id: "328",
            name: ["Health",  "Wellness"],
            children: []
          },
        ],
        14: [
          {
            id: "327",
            name: ["Nature",  "Adventure"],
            children: []
          },
          {
            id: "326",
            name: ["Culture",  "History"],
            children: []
          },
          {
            id: "329",
            name: ["Food",  "Drink"],
            children: []
          },
          {
            id: "328",
            name: ["Health",  "Wellness"],
            children: []
          },
        ],
        16: [
          {
            id: "327",
            name: ["Nature",  "Adventure"],
            children: []
          },
          {
            id: "326",
            name: ["Culture",  "History"],
            children: []
          },
          {
            id: "329",
            name: ["Food",  "Drink"],
            children: []
          },
          {
            id: "328",
            name: ["Health",  "Wellness"],
            children: []
          },
        ],
      },
      hotCountryList: [
        {
          id: null,
          name: "HOT",
        },
        {
          name: "China",
          id: "285",
        },
        {
          id: "18",
          name: "Indonesia",
        },
        {
          id: "305",
          name: "Japan",
        },
        {
          id: "15",
          name: "Korea",
        },
        {
          id: "14",
          name: "Thailand",
        },
        {
          id: "16",
          name: "Vietnam",
        },
      ],
      lineOffset: 0,
      lineWidth: 0,
      hotTabContentList: [],
      tabDescList: [
        {
          descTitle: "Guide",
          id: 330,
        },
        {
          descTitle: "Reviews",
          id: 388,
        },
        {
          descTitle: "Advice",
          id: 387,
        },
      ],
      isEnterFlag: false,
      timeOutTimer: null,
      cityPath: "/api/t/city/list",
    }
  },
  created() {
    console.log(typeof process.env.countryObj)
    this.hotTabContentList = this.hotAllTabObject[this.activeName];
    if (document.querySelector('meta[id="hotCountryList"]')) {
      var hotCountryList = document
      .querySelector('meta[id="hotCountryList"]')
      .getAttribute("content");
      if (hotCountryList) {
        this.hotAllTabObject = JSON.parse(hotCountryList);
      }
      document.head.removeChild(
        document.querySelector('meta[id="hotCountryList"]')
      );
    } else if (process.env.countryObj) {
      this.hotAllTabObject = process.env.countryObj;
    } else {
      this.getCityList();
    }
  },
  mounted() {
    this.lineOffset = this.$refs["tab" + this.tabActiveIndex][0].offsetLeft;
    this.lineWidth = this.$refs["tab" + this.tabActiveIndex][0].offsetWidth;
  },
  methods: {
    async getCityList() {
      const list = [
        {
          name: "China",
          id: "285",
        },
        {
          id: "18",
          name: "Indonesia",
        },
        {
          id: "305",
          name: "Japan",
        },
        {
          id: "15",
          name: "Korea",
        },
        {
          id: "14",
          name: "Thailand",
        },
        {
          id: "16",
          name: "Vietnam",
        },
      ];
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        let data = await postData(this.cityPath, {
          json: {
            parentId: item.id,
          },
        });
        if (data.length) {
          const filterList = data.filter((v) => v.isHot + "" === "1");
          if (filterList.length) {
            this.hotAllTabObject[item.id].forEach((element, index) => {
              element.children = filterList.slice(index * 6, (index + 1) * 6);
            });
          }
        }
      }
    },
    handleEnter(val, index) {
      this.activeName = val.id;
      this.tabActiveIndex = index;
      this.lineOffset = this.$refs['tab'+this.tabActiveIndex][0].offsetLeft;
      this.lineWidth = this.$refs['tab'+this.tabActiveIndex][0].offsetWidth;
      this.hotTabContentList = this.hotAllTabObject[this.activeName];
    },

    handleChooseCountry(curr) {
      let routeData = null;
      if (curr.id) {
        routeData = this.$router.resolve({
          path: `/travel/popular-country/${replaceSpacesWithDash(
            curr.name
          )}`, // path 要跳转的路由地址
          query: {
            country: curr.id,
            peo: curr.name.charAt(0),
          },
        });
      } else {
        routeData = this.$router.resolve({
          path: `/discover`, // path 要跳转的路由地址
        });
      }
      // window.open(routeData.href, "_blank");
      return routeData.href
    },
    handleClickTrip(val) {
      // alert(33)

      let path = `/travel/popular-country/${replaceSpacesWithDash(
        val.name.join("")
      )}`;
      let query = {
        triptype: val.id,
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      return routeData.href;
      // window.open(routeData.href, "_blank");
    },

    handleChildEnter(val, sign, parentInfo) {
      this.isEnterFlag = true;
      clearTimeout(this.timeOutTimer);
      this.timeOutTimer = setTimeout(() => {
        if (this.isEnterFlag) {
          this.handleChooseCity(val, sign, parentInfo);
        }
      }, 5000);
    },
    handleChildLeave(val, sign) {
      this.isEnterFlag = false;
      clearTimeout(this.timeOutTimer);
    },
    handleChooseCity(val, tab, parentInfo, flag = true) {
      const countryInfo = this.hotCountryList.find(
        (item) => item.id + "" === val.parentId + ""
      );
      let query = {
        country: val.parentId,
        city: val.id,
        peo: countryInfo.name.charAt(0),
        triptype: parentInfo.id,
      };
      let path = `/travel/popular-country/${replaceSpacesWithDash(
        countryInfo.name
      )}/${replaceSpacesWithDash(val.name)}/${replaceSpacesWithDash(
        parentInfo.name.join("")
      )}`;
      if (tab) {
        query.articleTypeId = tab.id;
        path = `/travel/popular-country/${replaceSpacesWithDash(
          countryInfo.name
        )}/${replaceSpacesWithDash(val.name)}/${replaceSpacesWithDash(
          parentInfo.name.join("")
        )}/${replaceSpacesWithDash(tab.descTitle)}`;
      }
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      if (flag) {
        window.open(routeData.href, "_blank");
        return;
      }
      return routeData.href
    }
  }
}
</script>

<style lang="scss" scoped>

.tabLine {
  position: absolute;
  bottom: 0;
  width: 0; /* 小横线的宽度 */
  height: 2px; /* 小横线的高度 */
  background: rgba(193, 89, 80, 1);
  transition: transform 0.5s ease; /* 动画效果 */
}
.hotTabList {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  height: 3.75rem;
  position: relative;
  background: rgba(255,255,255, 1);
  .hotTabItem {
    min-width: 15.3%;
    max-width: 15.3%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    &:first-child {
      min-width: 8%;
      max-width: 8%;
    }
  }
}
.hotTabContent {
  min-height: 26rem;
  max-height: 26rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: rgba(255,255,255, 1);
  box-sizing: border-box;
  padding-bottom: .5rem;
  .hotTabContentItem {
    flex: 1;
    display: flex;
    align-items: center;
    .hotTabContentTitle {
      min-width: 8% !important;
      max-width: 8% !important;
      text-align: center;
      a {
        color: rgb(96, 99, 112) !important;
        font-size: 1.2rem;
      }
    }
    .hotTabContentList {
      display: flex;
      flex: 1;
      .hotTabContentTxt {
        min-width: 15.3%;
        max-width: 15.3%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .h3Title {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          .h3TitleHref {
            &:hover {
              text-decoration: underline;
              h3 {
                color:#3c3c43;
              }
            }
            h3 {
              text-align: center;
            }
          }
        }
        .h3Title:hover{
          text-decoration:underline !important;
        }
        .h4Title {
          height: 1.3rem;
          display: flex;
          align-items: center;
          margin-top: 0.5rem;
          .h4TitleHref {
            margin: 0 0.5rem;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              right: -0.5rem;
              width: 1px;
              height: 1rem;
              background: #969aa9;
            }
            &:hover {
             text-decoration: underline !important;
              h4 {
                text-decoration: underline !important;
              }
            }
            &:last-child {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>