<template>
  <div class="articleListWrapper">
    <div class="leftContainer">
      <div class="leftContainerTab">
        <div
          class="leftContainerTabItem"
          v-for="item in menuList"
          :key="item.name"
          :class="checkTabName == item.name ? 'checkactive' : ''"
          @mouseenter="handleMenuEnter(item.name)"
          @mouseleave="isShowHotCountry = false"
          @click="handleToggleTab(item.name)"
        >
          <div class="tabContent">
            <div class="homeIcon">
              <img v-if="checkTabName != item.name" :src="item.icon" alt="" />
              <img
                v-if="checkTabName == item.name"
                :src="item.activeIcon"
                alt=""
              />
            </div>
            <a :href="openTab(item.name)" class="lx-text-12 lg-text-3c3c43 ">{{ item.name }}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      v-show="isShowHotCountry"
      class="concatWrapper"
      @mouseenter="isShowHotCountry = true"
      @mouseleave="isShowHotCountry = false"
    >
      <div class="concatWrapperHeader">Hot Country</div>
      <div class="concatWrapperMenu">
        <ul class="concatWrapperNav">
          <li
            v-for="(item, index) in letterList"
            :class="{ active: activeNavIndex == index }"
            :key="item"
            @click.stop="scrollOn(item, index)"
          >
            {{ item }}
          </li>
        </ul>
        <ul
          class="concatWrapperNavMenu"
          id="concatWrapperNavMenu"
          @scroll="scrollMenu"
        >
          <li
            v-for="item in peoArrayList"
            :key="item.key"
            class="concatWrapperNavMenuItem"
          >
            <div class="navMenuKey">{{ item.key }}</div>
            <div
              class="navMenuList"
              v-for="curr in item.list"
              :key="curr.id"
              @click.stop="handleChooseCountry(curr, item)"
            >
              {{ curr.name }}
            </div>
          </li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "TabComponent",
  data() {
    return {
      menuList: [
        {
          name: "Hot",
          icon: require("@/assets/img/home/134.png"),
          activeIcon: require("@/assets/img/home/134_active.png"),
        },
        {
          name: "Discover",
          icon: require("@/assets/img/home/135.png"),
          activeIcon: require("@/assets/img/home/135_active.png"),
        },
        {
          name: "Groups",
          icon: require("@/assets/img/home/136.png"),
          activeIcon: require("@/assets/img/home/136_active.png"),
        },
        {
          name: "FAQ",
          icon: require("@/assets/img/home/137.png"),
          activeIcon: require("@/assets/img/home/137_active.png"),
        },
        {
          name: "News",
          icon: require("@/assets/img/home/138.png"),
          activeIcon: require("@/assets/img/home/138_active.png"),
        },
      ],
      isShowHotCountry: false,
      peoArrayList: [],
      peoArrayList: [
        {
          key: "*",
          list: [
            {
              name: "China",
              id: "285",
            },
            {
              name: "Indonesia",
              id: "18",
            },
            {
              name: "Japan",
              id: "305",
            },
            {
              name: "Korea",
              id: "15",
            },
            {
              name: "Thailand",
              id: "14",
            },
            {
              name: "Vietnam",
              id: "16",
            },
          ],
        },
        {
          key: "A",
          list: [
            {
              name: "Afghanistan",
              id: "294",
            },
            {
              name: "Armenia",
              id: "295",
            },
            {
              name: "Azerbaijan",
              id: "296",
            },
          ],
        },
        {
          key: "B",
          list: [
            {
              name: "Bahrain",
              id: "297",
            },
            {
              name: "Bangladesh",
              id: "28",
            },
            {
              name: "Bhutan",
              id: "298",
            },
            {
              name: "Brunei",
              id: "299",
            },
          ],
        },
        {
          key: "C",
          list: [
            {
              name: "Cambodia",
              id: "25",
            },
            {
              name: "China",
              id: "285",
            },
            {
              name: "Cyprus",
              id: "300",
            },
          ],
        },
        {
          key: "E",
          list: [
            {
              name: "East timor",
              id: "301",
            },
          ],
        },
        {
          key: "G",
          list: [
            {
              name: "Georgia",
              id: "302",
            },
          ],
        },
        {
          key: "I",
          list: [
            {
              name: "India",
              id: "18",
            },
            {
              name: "Indonesia",
              id: "19",
            },
            {
              name: "Iran",
              id: "303",
            },
            {
              name: "Iraq",
              id: "304",
            },
            {
              name: "Israel",
              id: "29",
            },
          ],
        },
        {
          key: "J",
          list: [
            {
              name: "Japan",
              id: "33",
            },
            {
              name: "Jordan",
              id: "306",
            },
          ],
        },
        {
          key: "K",
          list: [
            {
              name: "Kazakhstan",
              id: "306",
            },
            {
              name: "Korea",
              id: "15",
            },
            {
              name: "Kuwait",
              id: "307",
            },
            {
              name: "Kyrgyzstan",
              id: "308",
            },
          ],
        },
        {
          key: "L",
          list: [
            {
              name: "Laos",
              id: "24",
            },
            {
              name: "Lebanon",
              id: "309",
            },
          ],
        },
        {
          key: "M",
          list: [
            {
              name: "Malaysia",
              id: "17",
            },
            {
              name: "Maldives",
              id: "30",
            },
            {
              name: "Mongolia",
              id: "310",
            },
            {
              name: "Myanmar",
              id: "27",
            },
          ],
        },
        {
          key: "N",
          list: [
            {
              name: "Nepal",
              id: "23",
            },
            {
              name: "North Korea",
              id: "311",
            },
          ],
        },
        {
          key: "O",
          list: [
            {
              name: "Oman",
              id: "312",
            },
          ],
        },
        {
          key: "P",
          list: [
            {
              name: "Pakistan",
              id: "31",
            },
            {
              name: "Palestine",
              id: "313",
            },
            {
              name: "Philippines",
              id: "22",
            },
          ],
        },
        {
          key: "Q",
          list: [
            {
              name: "Qatar",
              id: "33",
            },
          ],
        },
        {
          key: "S",
          list: [
            {
              name: "Saudi Arabia",
              id: "32",
            },
            {
              name: "Singapore",
              id: "20",
            },
            {
              name: "Sri Lanka",
              id: "26",
            },
            {
              name: "Syria",
              id: "314",
            },
          ],
        },
        {
          key: "T",
          list: [
            {
              name: "Tajikistan",
              id: "315",
            },
            {
              name: "Thailand",
              id: "14",
            },
            {
              name: "Turkey",
              id: "316",
            },
            {
              name: "Turkmenistan",
              id: "317",
            },
          ],
        },
        {
          key: "U",
          list: [
            {
              name: "United Arab Emirates",
              id: "21",
            },
            {
              name: "Uzbekistan",
              id: "318",
            },
          ],
        },
        {
          key: "V",
          list: [
            {
              name: "Vietnam",
              id: "16",
            },
          ],
        },
        {
          key: "Y",
          list: [
            {
              name: "Yemen",
              id: "293",
            },
          ],
        },
      ],
      activeNavIndex: 0,
      checkTabName: "",
      letterList: [],
    };
  },
  watch: {
    $route(to, from) {
      if (to.meta && to.meta.tab) {
        this.checkTabName = to.meta.tab;
      } else {
        this.checkTabName = "";
      }
    },
  },
  created() {
    if (this.$route.meta && this.$route.meta.tab) {
      this.checkTabName = this.$route.meta.tab;
    }
    // 获取26个英文字母大写
    this.peoArrayList.forEach((ele) => {
      this.letterList.push(ele.key);
    });
  },
  methods: {
    handleChooseCountry(curr, val) {
      // console.log(curr, val)
      // this.$router.push({
      //   path: `/travel/popular-country/${this.replaceSpacesWithDash(curr.name)}`,
      //   query: {
      //     country: curr.id,
      //     peo: val.key + "" === "*" ? 'Hot' : val.key
      //   }
      // })
      let routeData = this.$router.resolve({
        path: `/travel/popular-country/${this.replaceSpacesWithDash(
          curr.name
        )}`, // path 要跳转的路由地址
        query: {
          country: curr.id,
          peo: val.key + "" === "*" ? "Hot" : val.key,
        },
      });
      window.open(routeData.href, "_blank");
    },
    replaceSpacesWithDash(input) {
      return input.replace(/\s+/g, "-");
    },
    openTab(val) {
      switch (val) {
        case "Hot": {
          return "/";
        }
        case "Discover": {
          return "/discover";
        }
        case "Groups": {
          return "/groups";
        }
        case "FAQ": {
          return "/hot-travel-faq";
        }
        case "News": {
          return "/News-This-Week";
        }
      }
    },
    handleToggleTab(val) {
      switch (val) {
        case "Hot": {
          this.$router.push({
            path: "/",
          });
          break;
        }
        case "Discover": {
          let routeData = this.$router.resolve({
            path: "/discover", // path 要跳转的路由地址
          });
          window.open(routeData.href, "_blank");
          break;
        }
        case "Groups": {
          let routeData = this.$router.resolve({
            path: "/groups", // path 要跳转的路由地址
          });
          window.open(routeData.href, "_blank");
          break;
        }
        case "FAQ": {
          this.$router.push({
            path: "/hot-travel-faq",
          });
          break;
        }
        case "News": {
          this.$router.push({
            path: "/News-This-Week",
          });
          window.open(routeData.href, "_blank");
          break;
        }
      }
    },
    handleMenuEnter(name) {
      if (name === "Discover") {
        this.isShowHotCountry = true;
      }
    },
    scrollMenu(e) {
      const scrollItems = document.querySelectorAll(
        ".concatWrapperNavMenuItem"
      );
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        const judge =
          e.target.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop - 44;
        if (judge) {
          this.activeNavIndex = i;
          break;
        }
      }
    },
    scrollOn(val, index) {
      this.activeNavIndex = index;
      let jump = document.getElementsByClassName("concatWrapperNavMenuItem");
      const ele = document.getElementById("concatWrapperNavMenu");
      // 获取需要滚动的距离
      let total = jump[index].offsetTop - 44;
      ele.scrollTop = total;
      ele.animate({ scrollTop: total }, 400);
      console.log(total, "2313213039213921939123-----");
    },
  },
};
</script>

<style lang="scss">
.articleListWrapper {
  display: flex;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 3.75rem;
  bottom: 0rem;
  // z-index: 100000 !important;
  z-index: 10 !important;

  .leftContainer {
    // z-index: 100000 !important;
    z-index: 10 !important;
    .leftContainerTab {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background-color: #fff;
      // justify-content: space-around;

      .leftContainerTabItem {
        display: flex;
        flex-direction: column;
        color: #1f2129;
        position: relative;
        cursor: pointer;
        position: relative;

        .tabContent {
          
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          padding: .6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80%;
          height: 80%;
          margin-left: 10%;
          margin-top: 10%;
          border-radius: 3px;
          .homeIcon {
            width: 20px;
            img {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
        }

        &.checkactive {
          .tabContent {
            color: #000;
            background-color: #f6ce4f;
          }
        }

        // <!-- 229,88,0 rgba(255,204,50,.12) -->
        &:hover {
          .tabContent {
            color: rgba(229, 88, 0, 1);
             background: rgba(246,206,79,.5);
          }
        }

        a {
          // margin-top: .5rem;
          // font-size: 1.25rem !important;
          // color: #999999 !important;
        }
      }
    }

    .tabItemContainer {
      height: 15vh;

      &.hotWrapper {
        position: relative;
      }
    }
  }
}

@keyframes move {
  from {
    left: 0;
  }
  to {
    left: 4rem;
  }
}
.concatWrapper {
  width: 26.5rem;
  height: 31.25rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  background: #fff;
  z-index: 100 !important;
  box-sizing: border-box;
  padding: 0.5rem;
  top: 0;
  left: 4rem;
  animation-name: move; /* 使用上面定义的动画名称 */
  animation-duration: 0.5s; /* 动画持续时间 */
  animation-timing-function: ease-in-out; /* 动画速度曲线 */
  animation-iteration-count: 1; /* 动画无限次数播放 */
  .concatWrapperHeader {
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
    color: #000;
    background-color: #fff;
  }

  .concatWrapperMenu {
    flex: 1;
    display: flex;
    overflow: hidden;

    .concatWrapperNav {
      width: 50px;
      height: 100%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #ddd;

      li {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;

        &:hover {
          background: rgb(255, 225, 0);
        }

        &.active {
          background: #ffcc32;
        }
      }
    }

    .concatWrapperNavMenu {
      flex: 1;
      overflow-y: scroll;

      &::-webkit-scrollbar-track {
        width: 5px;
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        width: 5px;
        height: 10px;
        border-radius: 3px;
        background-color: #d8d8d8;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background: transparent;
      }

      &::-webkit-scrollbar-track {
        width: 5px;
        background: transparent;
      }

      li {
        width: 100%;

        .navMenuKey {
          width: 100%;
          background-color: rgb(247, 248, 252);
          position: sticky;
          top: -1px;
          line-height: 28px;
          height: 28px;
          font-size: 12px;
          color: rgb(31, 33, 41);
          padding-left: 12px;
          text-align: left;
          border-top: 1px solid rgba(216, 216, 216, 0.6);
          border-right: 1px solid rgba(216, 216, 216, 0.6);
        }

        .navMenuList {
          height: 40px;
          line-height: 40px;
          cursor: pointer;
          font-size: 1rem;
          border-top: 1px solid rgba(216, 216, 216, 0.6);
          border-right: 1px solid rgba(216, 216, 216, 0.6);
          text-align: left;
          padding-left: 20px;
          color: #000;
        }
      }
    }
  }
}
</style>