<!--
 * @Author: r_yanxu r_yanxu@ctx.com.sg
 * @LastEditors: r_yanxu r_yanxu@ctx.com.sg
 * @Date: 2025-01-17 14:55:39
 * @Description: 
-->
<template>
  <div class="login">
    <b-modal v-model="isLoginModeActive" :width="450" scroll="keep" :has-modal-card="fullScreen">
      <div class="card">
        <div class="google">
          <b-button type="is-warning" size="is-large" class="googleBtn">Continue with Google</b-button>
          <p>OR</p>
        </div>
        <section class="formArea">

          <b-field label="Use Email address to login">
            <b-input placeholder="Email" type="text" size="is-medium" v-model="loginInfo.email"
              message="This email is invalid"></b-input>
          </b-field>

          <b-field label="Password" class="passwordField">
            <b-input placeholder="Password" size="is-large" v-model="loginInfo.password" type="password" required>
            </b-input>
            <!-- <template #message>
              <div>Password is too short</div>
              <div>Password must have at least 8 characters</div>
            </template> -->
          </b-field>
        </section>

        <section class="actions">
          <b-button type="is-warning" size="is-large" class="loginBtn" @click="LoginFun">Login</b-button>
          <p class="tips">未注册用户，首次登录即是注册</p>
        </section>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { userRegister, userLogin, userLoginOrReg } from '@/api/user';
export default {
  components: {},
  computed: {
    isLoginModeActive: {
      get() {
        return this.$store.state.user.isLoginModeActive
      },
      set(val) {
        this.$store.state.user.isLoginModeActive = val
      }
    },
  },
  data() {
    return {
      fullScreen: false,
      loginInfo: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    cancel() {
      this.$store.state.user.isLoginModeActive = false
    },
    async LoginFun() {
      let result = await userLoginOrReg(this.loginInfo)
      if (result.id && result.apiToken) {
        this.$buefy.toast.open({
          message: `<p style='color:#fff;font-size:14px;'>Login Succeeded!<p/>`,
          type: 'is-success'
        })
        localStorage.setItem("crm-nickName", result.nickName);
        localStorage.setItem("crm-token", result.apiToken);
        localStorage.setItem("crm-id", result.id);
        localStorage.setItem("crm-email", result.email);
        this.$store.commit('setUserInfo')
        this.$store.commit('setLoginModeActive', false)
      } else {
        this.$buefy.toast.open({
          message: `<p style='color:#fff;font-size:14px;'>${result}`,
          type: 'is-danger'
        })
      }
    }
  },
  created() { },
  mounted() { },
}
</script>
<style scoped lang='scss'>
:deep(.modal-background) {
  background: rgba(10, 10, 10, 0.3) !important;
}

.card {
  min-height: 450px;
  padding: 40px 30px;

  .google {
    text-align: center;

    .googleBtn {
      background: #F6CE4F;
      height: 48px;
      width: 240px;
    }

    p {
      font-size: 18px;
      color: #000000;
      line-height: 18px;
      margin-top: 18px;
    }
  }

  .formArea {
    margin-top: 18px;

    :deep(.label) {
      color: #666666 !important;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 21px;
    }

    :deep(input) {
      height: 40px;
      font-size: 16px;
      line-height: 40px;
    }

    .passwordField {
      margin-top: 20px;
    }
  }

  .actions {
    margin-top: 40px;
    text-align: center;

    .loginBtn {
      height: 40px;
      font-size: 16px;
      line-height: 40px;
      background: #F6CE4F;
    }

    .tips {
      color: #999999;
      font-size: 12px;
      margin-top: 10px;
    }
  }
}
</style>
