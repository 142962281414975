<template>
    <div class="login hero is-warning">
      <div class="box boxContainer">
        <div class="googlelogin">
          <b-button disabled expanded type="is-link"
            >Continue with Google</b-button
          >
        </div>
  
        <div class="title is-h3 email">or</div>
        <b-field label="use Email address to login" type="is-danger">
          <b-input
            type="email"
            value="john@"
            maxlength="30"
            v-model="user.userName"
          >
          </b-input>
        </b-field>
        <b-field label="Password">
          <b-input
            type="password"
            value="iwantmytreasure"
            password-reveal
            v-model="user.password"
          >
          </b-input>
        </b-field>
        <div class="content">
          <b-button type="is-success" size="is-small" expanded @click="loginBtn"
            >登录</b-button
          >
        </div>
        <div class="level is-mobile">
          <div class="level-item"><a href="">Beta version with limited functions</a></div>
          <div class="level-item"><span>|</span></div>
  
          <div class="level-item"><a href="">Stay Tuned </a></div>
        </div>
        <div class="container">
          Please send email to power998@163.com if interest arises;
        </div>
      </div>
    </div>
  </template>
  <script>
  import postData from "@/common/http";
  export default {
    data() {
      return {
        user: {},
        loginPath: "/login",
      };
    },
    methods: {
      async loginBtn() {
        let data = await postData(this.loginPath, {
          json: this.user,
        });
        if (data.token && data.userName) {
          localStorage.setItem("crm-userName", data.userName);
          localStorage.setItem("crm-token", data.token);
          window.location.href = "/config/articleList";
          this.$forceUpdate();
        }
      },
    },
  };
  </script>
  <style lang="scss" scoped>
  .login {
    // margin-top:10rem;
    padding-top:5rem;
    display: flex;
    justify-content: center;
    .googlelogin{
      margin-top:1rem;
    }
    .boxContainer {
      margin: 0 auto;
      width: 30%;
      min-width: 280px;
      height: 35rem;
      .email {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  </style>