<template>
  <div class="articleType">
    <section class="box">
      <div class="title is-5">
        {{
          (dataToChildren.id ? "编辑 " : "创建 ") +
          dataToChildren.typeIdentity +
          " 类型"
        }}
      </div>
      <b-field label="类型ID :" horizontal v-if="dataToChildren.id">
        {{ dataToChildren.id }}
      </b-field>
      <b-field label="类型名称:" horizontal>
        <b-input
          size="is-small"
          v-model="dataToChildren.name"
          required
        ></b-input>
      </b-field>
      <b-field label="类型描述:" horizontal>
        <b-input
          size="is-small"
          v-model="dataToChildren.desc"
          type="textarea"
          required
        ></b-input>
      </b-field>
      <b-field label="推荐上热门:" horizontal>
        <b-radio v-model="isHot" size="is-small" native-value="1" required
          >是</b-radio
        >
        <b-radio v-model="isHot" size="is-small" native-value="0" required
          >否</b-radio
        >
      </b-field>
      <!-- <b-field label="父节点:" horizontal>
        <b-input
          size="is-small"
          v-model="dataToChildren.parentId"
          required
        ></b-input>
      </b-field> -->
      <!-- {{ dataToChildren }}     -->
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <!-- <b-button @click="upload" size="is-small"> 上传</b-button> -->
          <b-button size="is-small" type="is-success" @click="confirmBtn">
            确认</b-button
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import postData from "../../../common/http";
export default {
  props: {
    dataToChildren: {
      type: Object,
    },
  },
  data() {
    return {
      articlePath: "/commonType/update",
      isHot:0,
    };
  },
  created() {
    this.isHot = this.dataToChildren.isHot;
  },
  methods: {
    async upload() {
      let arr = [
    {"name": "temples", "desc": "寺庙"},
    {"name": "bazaar", "desc": "集市"},
    {"name": "heritage", "desc": "遗产"},
    {"name": "ricefields", "desc": "稻田"},
    {"name": "festivals", "desc": "节日"},
    {"name": "monsoon", "desc": "季风"},
    {"name": "spices", "desc": "香料"},
    {"name": "pagodas", "desc": "塔"},
    {"name": "markets", "desc": "市场"},
    {"name": "sari", "desc": "纱丽"},
    {"name": "buddhism", "desc": "佛教"},
    {"name": "souks", "desc": "传统市场"},
    {"name": "karst", "desc": "喀斯特地貌"},
    {"name": "jungles", "desc": "丛林"},
    {"name": "yurts", "desc": "蒙古包"}
]

;
      for (var i = 0; i < arr.length; i++) {
        postData(this.articlePath, {
          json: { typeIdentity: "keyword", ...arr[i++],isHot:1 },
        });
      }
    },
    async confirmBtn() {
      if (!this.dataToChildren.id) delete this.dataToChildren.id;
      let data = await postData(this.articlePath, {
        json: { ...this.dataToChildren, isHot: this.isHot },
      });

      if (data == null) {
        this.$buefy.toast.open("Done successfully");
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.box{
  min-height: 95vh;
  magin-top: 2vh;}
</style>