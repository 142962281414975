<template>
  <section class="map-content-container">
    <h1 class="title">{{ $route.params.name }}</h1>
    <div class="content-box" v-for="(item, index) in allCountryList" :key="index">
      <div class="header">{{ item.key }}</div>
      <div class="list">
        <div class="item" v-for="subItem in item.list" :key="subItem.name">
          <!-- <router-link :to="subItem.name">{{ subItem.name }}</router-link> -->
          <a :href="'https://leisuretripinasia.com'+openHotCountry(subItem)" target="_blank" class="itemContent">{{ subItem.name }}</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { timestampToDateString, replaceSpacesWithDash } from "@/utils/utils";
export default {
  name: "mapContent",
  data() {
    return {
      list: [
        {
          name: "China",
          city: [
            {
              name: "Beijing",
            },
            {
              name: "Shanghai",
            },
            {
              name: "Hong Kong",
            },
            {
              name: "Taiwan",
            },
            {
              name: "Guangzhou",
            },
            {
              name: "Shenzhen",
            },
          ],
        },
        {
          name: "India",
          city: [
            {
              name: "Delhi",
            },
            {
              name: "Mumbai",
            },
            {
              name: "Kolkata",
            },
            {
              name: "Chennai",
            },
            {
              name: "Bangalore",
            },
            {
              name: "Hyderabad",
            },
            {
              name: "Ahmedabad",
            },
          ],
        },
        {
          name: "USA",
          city: [
            {
              name: "New York",
            },
            {
              name: "Los Angeles",
            },
            {
              name: "Chicago",
            },
            {
              name: "Houston",
            },
          ],
        },
        {
          name: "UK",
          city: [
            {
              name: "London",
            },
            {
              name: "Manchester",
            },
            {
              name: "Birmingham",
            },
            {
              name: "Glasgow",
            },
          ],
        },
      ],
      allCountryList: this.$store.state.allCountryList
    };
  },
  methods: {
    openHotCountry(item) {
      let path = `/travel-country/`+replaceSpacesWithDash(item.name);
      let query = {
        countryId: item.id,
        countryName: item.name
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      return routeData.href;
    },
  }
};
</script>
<style lang="scss" scoped>
.map-content-container {
  flex: 1;
  .title {
    font-size: 40px;
    color: #000;
  }
  .content-box {
    padding: 24px 0;
    border-bottom: 1px solid #dae0e6;
    .header {
      height: 30px;
      line-height: 30px;
      font-size: 20px;
      color: #000;
      font-weight: 600;
    }
    .list {
      margin-top: 10px;
      display: flex;
      gap: 8px 5px;
      flex-wrap: wrap;
      .item {
        width: 16%;
        height: 24px;
        line-height: 24px;
        font-size: 16px;
        .itemContent {
          color: #374559;
          &:hover {
            color: #e65800 !important;
            text-decoration: underline !important;
          }
        }
      }
    }
  }
}
</style>
