export default {
    state: {
        state: {
            tab: '1', //tab
            peo: 'Hot', //国家
            country: '',
            countryName: '',

            city: '',
            cityName: '',

            triptype: '',
            triptypeName: '',

            articleTypeId: '',
            articleTypeName: '',
        },
        hotCities: [],
        tags: [],
    },
    mutations: {
        resetState(state) {
            state.state = {
                tab: '1', //tab
                peo: 'Hot', //国家
                country: '',
                countryName: '',

                city: '',
                cityName: '',

                triptype: '',
                triptypeName: '',

                articleTypeId:'',
                articleTypeName:'',
            }
        },
        setHotCities(state, data) {
            state.hotCities = data
        },
        resetTags(state) {
            state.tags = []
        }
    }
}
