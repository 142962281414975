<template>
  <div>
    <quill-editor
      :id="randomId(3)"
      v-model="content"
      :ref="editorRef"
      @change="onEditorChange($event)"
      class="editor"
      style="height: 600px;"
    />
  </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "QuillEditorCom",
  components: { quillEditor },
  props: {
    // 编辑器的内容
    editorContent: {
      type: String,
      required: true,
    },
    editorRef: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: this.editorContent,
    };
  },
  computed: {
    //当前富文本实例
    editor() {
      return this.$refs.editorRef.quillEditor;
    },
  },
  watch: {
    editorContent() {
      this.content = this.editorContent;
    },
  },
  methods: {
    randomId(len) {
      var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      var tempLen = chars.length,
        tempStr = "";
      for (var i = 0; i < len; ++i) {
        tempStr += chars.charAt(Math.floor(Math.random() * tempLen));
      }
      return tempStr;
    },
    // 富文本编辑器 内容改变事件
    onEditorChange({ html }) {;
      this.$emit("input", this.content);
    },
  },
};
</script>
<style>
</style>