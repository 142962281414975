import postData from "@/common/cHttp";

//获取城市列表
export const getCityList = (params) => {
    return postData('/api/t/city/list', {
        json: params,
    })
}

//获取文章列表
export const getArticleList = (params) => {
    return postData('/api/t/article/homeList', {
        json: params,
    })
}

//根据景点id查询文章
export const getListBySceneId = (params) => {
    return postData('/api/t/article/getListBySceneId', {
        json: params,
    })
}

//获取文章详情
export const getArticleDetails = (params) => {
    return postData('/api/t/article/detail', {
        json: params,
    })
}
