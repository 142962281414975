<template>
  <section class="section">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12">
         
            <h1 class="title is-3 has-text-primary contactTitle">Contact Us</h1>
            
            <div class="content">
             <p>We value your  thoughs, ideas and offers! </p> 
              
              <p>
                If you have suggestions, inquiries, or
              just want to share your travel stories, we're all ears. 
              </p>
              <p>
                Your input
              helps us improve our services and provide you with the best travel
              experiences.
              </p>

              <b-button type="is-link" class="mt-5 is-medium" @click="sendEmail">
              <b-icon icon="email" class="mr-2"></b-icon>Send Us an Email
            </b-button>
            <p class="mt-3">
              <strong>Email:</strong>
              <a href="mailto:power998@163.com" class="has-text-link"
                >power998@163.com</a
              >
            </p>
            </div>
            
          
        </div>

        
        </div>

        <div class="columns mt-6">
          
        </div>
      </div>
      
  </section>
</template>
  
  <script>
export default {
  methods: {
    sendEmail() {
      window.location.href =
        "mailto:power998@163.com?subject=Inquiry about travel services&body=Hi, I would like to inquire about...";
    },
  },
};
</script>
  
  <style scoped>
.section {
  padding: 3rem 1.5rem;
}

.box {
  padding: 2rem;
  border-radius: 10px;
}

figure img {
  border-radius: 10px;
  object-fit: cover;
}

.card {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}

b-icon {
  font-size: 2.5rem;
}
.contactTitle {
  display: block;
  width: 50rem !important;
  margin: 2rem 0;
  min-height: 10vh;
}
a {
  font-weight: bold;
  color: #3273dc;
}

a:hover {
  text-decoration: underline;
}

b-button {
  font-size: 1.2rem;
}

.content {
  line-height: 2;
  font-size: 1.5rem;

}
</style>
  