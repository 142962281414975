<template>
  <div class="groupDetailContainer">
    <div class="groupDetailHeader">
      <h2>{{ groupName }}</h2>
      <div class="groupDetailHeaderDesc">
        <p><span>Backpacker:</span> 20w</p>
        <p><span>Q&W:</span> 2w</p>
      </div>
      <div class="content-button" @click="handleJoinCricle">Join</div>
    </div>
    <ul class="hotTabList">
      <li
        :class="tabActiveName == item.id ? 'active' : ''"
        v-for="(item, index) in tabs"
        :key="index"
      >
        <a>
          <h3 class="hotTitle">{{ item.name }}</h3>
        </a>
      </li>
    </ul>
    <div class="fllow-content">
      <textarea
        v-model="contentVal"
        class="textarea-wrapper"
        placeholder="Enter~~~~"
      ></textarea>
      <div class="fllow-footer">
        <ul class="footer-list"></ul>
        <div class="footer-button" @click="handleClickPublish">Publish</div>
      </div>
    </div>
    <ul class="us-list">
      <li class="us-item" v-for="(item, index) in usList" :key="index">
        <div class="item-content">
          <div class="header">
            <div class="header-left">
              <div class="header-pic">
                <img src="@/assets/img/avtar.png" alt="">
              </div>
              <div class="header-name">{{ item.author || "**A**" }}</div>
            </div>
            <div class="header-right">
              <div class="right-button">Fllow</div>
            </div>
          </div>
          <div class="middle">
            <div class="middle-content">{{ item.content }} </div>
          </div>
          <div class="item-footer">
            <div class="footer-left">
              <p class="date">{{ timestampToDateString(item.createTime, "mm-dd") }}</p>
            </div>
            <div class="footer-right">
              <div class="answer" @click="handleToggleIssuse(index, item)">issuse</div>
            </div>
          </div>
        </div>
        <div class="issuseWrapper" :class="checkArr.includes(index) ? 'issuse-show' : ''">
          <div class="issuseEnter">
            <div class="enter">
              <input v-model="item.enterContent" type="text" placeholder="Enter~~~~">
            </div>
            <div class="enterButton" @click="handleSubimtEnter(index, item)">Submit</div>
          </div>
          <ul class="contentIssuse">
            <li class="itemIssuse" v-for="(curr, currIndex) in item.issuseList" :key="currIndex">
              <div class="issuseLeft">
                <div class="authorImg">
                  <img src="@/assets/img/avtar.png" alt="">
                </div>
              </div>
              <div class="issuseRight">
                <div class="issuseRightHeader">
                  <div class="issuseHeaderName">{{ curr.author || "B**" }}</div>
                  <div class="issuseHeaderDate">{{ timestampToDateString(curr.createTime, "mm-dd") }}</div>
                </div>
                <div class="issuseMiddle">{{ curr.content }}</div> 
              </div>
              <!-- <div class="issuseHeader">
                <div class="issuseHeaderLeft">
                  <div class="authorImg">
                    <img src="@/assets/img/avtar.png" alt="">
                  </div>
                  <div class="issuseHeaderName">{{ curr.author || "B**" }}</div>
                </div>
                <div class="issuseHeaderRight">{{ timestampToDateString(curr.createTime, "mm-dd") }}</div>
              </div>
              <div class="issuseMiddle">{{ curr.content }}</div> -->
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getCirclePostList, addCirclePost, getCircleCommentList, addCircleCommentList, joinCircleGroup } from "@/api/circle";
export default {
  name: "groupDetail",
  data() {
    return {
      tabActiveName: "1",
      tabs: [
        {
          name: "Post",
          id: "1",
        },
        {
          name: "Q&W",
          id: "2",
        },
      ],
      contentVal: "",
      usList: [],
      checkArr: [],
      checkIndex: null,
      groupName: ""
    };
  },
  created() {
    console.log(window.location.href.split("groupDetail/")[1].split("?")[0])
    this.groupName = window.location.href.split("groupDetail/")[1].split("?")[0];
    this.getCirclePostList();
  },
  methods: {
    async handleJoinCricle(val) {
      let result = await joinCircleGroup({
        circleId: this.$route.query.groupId,
      });
      if (result == null) {
        this.$buefy.toast.open({
          message: "Success",
          type: "is-success",
        });
      }
    },
    handleToggleIssuse(index, val) {
      if (this.checkArr.includes(index)) {
        const findIndex = this.checkArr.findIndex((item) => item === index);
        this.checkArr.splice(findIndex, 1);
      } else {
        this.checkIndex = index
        this.checkArr.push(index);
        this.getCircleCommentList(val)
      }
    },
    handleClickPublish() {
      if (!this.contentVal) {
        this.$buefy.toast.open({
          message: "Enter Content",
          type: "is-warning",
        });
        return;
      }
      this.addCirclePost();
    },

    handleSubimtEnter(index, val) {
      console.log(val.enterContent, "评论内容")
      if (!val.enterContent) {
        this.$buefy.toast.open({
          message: "Enter Content",
          type: "is-warning",
        });
        return;
      }
      this.checkIndex = index
      this.addCircleCommentList();
    },
    async getCircleCommentList(val) {
      let result = await getCircleCommentList({
        postId: val.id,
      });
      if (result.length) {
        this.usList[this.checkIndex].issuseList = result
      }
      console.log(result,  "评论列表")
    },
    async addCircleCommentList() {
      let result = await addCircleCommentList({
        content: this.usList[this.checkIndex].enterContent,
        postId: this.usList[this.checkIndex].id,
      });
      if (result === null) {
        this.$buefy.toast.open({
          message: "Success",
          type: "is-success",
        })
        this.getCircleCommentList({
          id: this.usList[this.checkIndex].id
        });
        this.usList[this.checkIndex].enterContent = ""
      }
    },
    async getCirclePostList() {
      let result = await getCirclePostList({
        circleId: this.$route.query.groupId,
      });
      if (result) {
        this.usList = result.map(item => {
          return {
            ...item,
            enterContent: "",
            issuseList: []
          }
        });
      }
      console.log(result, "2312312312312312312");
    },
    async addCirclePost() {
      let result = await addCirclePost({
        content: this.contentVal,
        circleId: this.$route.query.groupId,
      });
      
      if (result === null) {
        this.$buefy.toast.open({
          message: "Success",
          type: "is-success",
        });
        this.contentVal = "";
        this.getCirclePostList();
      }
      console.log(result, "发送成功");
    },

    timestampToDateString(timestamp, format) {
      var date = new Date(); // 将时间戳转换为日期对象
      if (timestamp) {
        date = new Date(timestamp);
      }
      var year = date.getFullYear(); // 获取年份
      var month = (date.getMonth() + 1).toString().padStart(2, "0"); // 获取月份并补零
      var day = date.getDate().toString().padStart(2, "0"); // 获取日期并补零
      switch (format) {
      case "yyyy-mm-dd":
        return `${year.toString()}-${month}-${day}`;
      case "yyyy/mm/dd":
        return `${year.toString()}/${month}/${day}`;
      case "yyyy年mm月dd日":
        return `${year.toString()}年${month}月${day}日`;
      case "mm-dd":
        return `${month}-${day}`
      default:
        return `${year.toString()}-${month}-${day}`;
      }
    },
  },
};
</script>

<style lang="scss">
.groupDetailContainer {
  min-height: 190vh;
  margin-left: 6rem;
  margin-top: 1.5rem;
  box-sizing: border-box;
  padding: 0 8rem;
  .groupDetailHeader {
    width: 100%;
    height: 15rem;
    // background-color: rgba(0, 0, 0, 0.6);
    background-image:url("@/assets/img/1.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      color: #fff;
      font-size: 1.8rem;
    }
    .groupDetailHeaderDesc {
      margin-top: 0.5rem;
      display: flex;
      color: #fff;
      font-size: 1rem;
      p {
        margin-right: 0.5rem;
        span {
          color: #fff;
        }
      }
    }
    .content-button {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1a1a1a;
      background-color: #ffcc32;
      width: 8rem;
      height: 2rem;
      box-sizing: border-box;
      font-weight: 700;
      text-align: center;
      font-size: 1rem;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      transition: all 0.1s ease-in-out;
    }
  }
  .hotTabList {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 0.5rem 0;
    position: relative;
    margin-bottom: 1rem;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background: #e6e8f2;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 0 1rem;
      font-size: 1rem;

      a {
        color: rgb(96, 99, 112) !important;
      }
      &.active {
        font-weight: 600;
        position: relative;
        a {
          color: #000 !important;
        }
        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: -7px;
          height: 3px;
          background: #ffcc32;
        }
      }
    }
  }
  .fllow-content {
    width: 100%;
    height: 20rem;
    background-color: #fff;
    padding: 2rem;
    box-sizing: border-box;
    .textarea-wrapper {
      width: 100%;
      height: 14rem;
      overflow: hidden auto;
      background: #ddd !important;
      border-radius: 4px;
      color: rgb(31, 33, 41);
      font-size: 14px;
      line-height: 20px;
      resize: none;
      white-space: pre-wrap;
      border: none;
      outline: none;
      background-color: rgb(247, 248, 252);
      box-sizing: border-box;
      padding: 0.6rem;
    }
    .fllow-footer {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .footer-list {
        display: flex;
        align-items: center;
        li {
          margin-right: 0.8rem;
          border-radius: 2px;
          border: 0.5px solid rgb(201, 203, 214);
          position: relative;
          box-sizing: border-box;
          color: rgb(31, 33, 41);
          padding: 0.3rem 1rem;
          cursor: pointer;
        }
      }

      .footer-button {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        color: rgb(31, 33, 41);
        width: 5rem;
        height: 2rem;
        background-color: #ffd65b;
        cursor: pointer;
      }
    }
  }
  .us-list {
    margin-top: 1rem;
    .us-item {
      background-color: #fff;
      .item-content {
        box-sizing: border-box;
        padding: 1rem;
        margin-bottom: 0.5rem;
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .header-left {
            display: flex;
            align-items: center;
            .header-pic {
              width: 2rem;
              height: 2rem;
              border-radius: 50%;
              img {
                display: inline-block;
                width: 100%;
                height: 100%;
              }
            }
            .header-name {
              font-size: 1rem;
              font-weight: 500;
              color: rgb(31, 33, 41);
              margin-left: 0.5rem;
            }
          }
          .header-right {
            .right-button {
              padding: 0.3rem 0.8rem;
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              font-size: 1rem;
              font-family: "PingFang SC";
              border-radius: 2px;
              box-sizing: border-box;
              border: 1px solid rgb(255, 205, 50);
              font-weight: normal;
              color: rgb(31, 33, 41);
              background-color: rgba(255, 204, 50, 0.12);
              cursor: pointer;
            }
          }
        }
        .middle {
          .middle-content {
            margin: 0.6rem 0 0.6rem 2.5rem;
            font-size: 1rem;
            color: rgb(51, 51, 51);
            cursor: pointer;
          }
        }

        .item-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .footer-left {
            margin-left: 2.5rem;
            display: flex;
            align-items: center;
            .list {
              display: flex;
              li {
                box-sizing: border-box;
                padding: 0.2rem 0.4rem;
                border-radius: 2px;
                background-color: rgb(242, 244, 250);
                display: inline-flex;
                -webkit-box-align: center;
                align-items: center;
                margin-right: 0.2rem;
                font-size: 1rem;
              }
            }
            .date {
              font-size: 1rem;
              color: rgb(96, 99, 112);
              margin-left: 0.4rem;
            }
          }
          .footer-right {
            display: flex;
            align-items: center;
            .answer {
              font-size: 1rem;
              color: rgb(96, 99, 112);
              margin-right: 1.2rem;
              cursor: pointer;
            }
            .fav {
              font-size: 1rem;
              color: rgb(96, 99, 112);
            }
          }
        }
      }
      .issuseWrapper {
        background-color: #f7f8fc;
        box-sizing: border-box;
        padding: 1rem 4rem;
        display: none;
        width: 100%;
        &.issuse-show {
          display: inline-block;
        }
        .issuseEnter {
          display: flex;
          .enter {
            flex: 1;
            height: 3rem;
            input {
              border: none;
              background-color: #fff;
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              padding-left: .3rem;
              font-size: .8rem;
              &:focus {
                outline: 2px solid #ffcc32; /* 你想要的任何颜色 */
              }
            }
          }
          .enterButton {
            width: 6rem;
            height: 3rem;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            background-color: rgb(26, 26, 26);
            color: white;
            font-size: 1rem;
            cursor: pointer;
          }
        }
        .contentIssuse {
          .itemIssuse {
            margin-top: .8rem;
            // box-sizing: border-box;
            // padding-bottom: .8rem;
            display: flex;
            .issuseLeft {
              .authorImg {
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
                img {
                  width: 100%;
                  height: 100%;
                  display: inline-block;
                }
              }
            }
            .issuseRight {
              flex: 1;
              padding-left: 1rem;
              .issuseRightHeader {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .issuseHeaderName {
                  font-size: 1rem;
                  font-weight: 500;
                  color: rgb(31, 33, 41);
                }
                .issuseHeaderDate {
                  color: rgb(151, 154, 168);
                  font-size: 1rem;
                }
              }
              .issuseMiddle {
                color: rgb(31, 33, 41);
                font-size: 1rem;
                margin-top: 1rem;
                box-sizing: border-box;
                padding-bottom: 1rem;
                border-bottom: 1px solid rgb(151, 154, 168);
              }
            }
            // .issuseHeader {
            //   display: flex;
            //   align-items: center;
            //   justify-content: space-between;
            //   .issuseHeaderLeft {
            //     display: flex;
            //     align-items: center;
            //     .authorImg {
            //       width: 2rem;
            //       height: 2rem;
            //       border-radius: 50%;
            //       img {
            //         width: 100%;
            //         height: 100%;
            //         display: inline-block;
            //       }
            //     }
            //     .issuseHeaderName {
            //       font-size: 1rem;
            //       margin-left: 1rem;
            //       font-weight: 500;
            //       color: rgb(31, 33, 41);
            //     }
            //   }
            //   .issuseHeaderRight {
            //     color: rgb(151, 154, 168);
            //     font-size: 1rem;
            //   }
            // }
            // .issuseMiddle {
            //   color: rgb(31, 33, 41);
            //   font-size: 1rem;
            //   margin-top: 1rem;
            // }
          }
        }
      }
    }
  }
}
</style>