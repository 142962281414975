import Vue from "vue"
import VueRouter from 'vue-router'

import { replaceWithDashSpaces } from '@/utils/utils'

import main from '../views/main'
import config from '../views/config/index.vue'
import ArticleList from '../views/blog/articleList'
import Blog from '../views/blog/index.vue'
import Scene from '../views/blog/scene.vue'
import List from '../views/blog/list.vue'
import tabs from '../common/config/tabs'
// import Countries from '../views/countries/index'
import Login from '../views/user/login.vue'
import Editor from '../views/user/editor.vue'
import Hots from '../views/hots/index.vue'
import News from '../views/news/index.vue'
import Scenes from '../views/scenes/index.vue'
import Headline from '../views/headline/index.vue'

import AboutUs from "../views/blog/aboutus"
import ContactUs from "../views/blog/contactus"
import Sitemap from '../views/sitemap/index'
import MapContent from '../views/sitemap/mapContent'

import NewArtcleList from '../views/blog/newArtcleList.vue'  // 新版首页
import CricleIndex from '../views/circle/index.vue' // 圈子
import GroupDetail from '../views/circle/detail.vue' // 圈子详情
import Discover from '@/views/discover'
import ArticleDetail from '../views/circle/articleDetail'

Vue.use(VueRouter)

const routes = [
    {
        path: "/about-us",
        name: "about-us",
        component: AboutUs
    },
    {
        path: "/contact",
        name: "contactus",
        component: ContactUs
    },
    {
        name: "sitemap",
        path: "/sitemap",
        component: Sitemap,
        children: [
            {
                name: "mapContent",
                path: "/sitemap/:name",
                component: MapContent
            }
        ]
    },

    {
        path: "/hots",
        name: "hots",
        component: Hots
    },
    {
        path: "/news",
        name: "news",
        component: News
    },
    //等级规则
    {
        path: "/ratingRules",
        name: "ratingRules",
        component: () => import('@/views/ratingRules/index.vue')
    },
    //discover
    {
        path: "/discover",
        name: 'discover',
        component: Blog,

        meta: {
            title: "Discover"
        },
        children: [
            {
                path: "/discover/:countryName?/:cityName?/:triptypeName?",
                component: () => import('@/views/discover/index.vue'),
                name: "NewArtcleList",
                meta: {
                    tab: "Discover",

                    title: "Discover best travel experience"

                }
            }
        ]
    },
    //destinations
    {
        path: "/destinations",
        name: 'destinations',
        component: Blog,
        meta: {
            title: "Discover"
        },
        children: [
            {
                path: "/destinations/:countryName?/:cityName?/:triptypeName?",
                component: () => import('@/views/discover/index.vue'),
                name: "NewArtcleList",
                meta: {
                    tab: "Discover",

                    title: "Discover best travel experience"

                }
            }
        ]
    },
    {
        path: "/travel",
        name: 'travel',
        component: Blog,
        children: [
            {
                path: "popular-country/:countryName?/:cityName?/:triptypeName?/:introduction?/:question?",
                component: Discover,
                meta: {
                    tab: "Discover",
                    title: "Discover hot travel experience "

                }
            },
            {
                path: "popular-travel-types/:countryName?/:cityName?/:triptypeName?/:introduction?/:question?",
                component: Discover,
                meta: {
                    tab: "Discover",
                    title: "'Discover hot travel experience "

                }
            },
            {
                path: "popular-types/:countryName?/:cityName?/:triptypeName?/:introduction?/:question?",
                component: Discover,
                meta: {
                    tab: "Discover",
                    title: "'Discover hot travel experience "

                }
            },
            {
                path: "/travel/:countryName?/:cityName?/:triptypeName?/:introduction?/:question?",
                component: () => import('@/views/discover/index.vue'),
                meta: {
                    tab: "Discover",
                    title: "'Discover hot travel experience "

                }
            }
        ]
    },

    {
        path: "/travel-news",
        name: "Details",
        component: Blog,
        children: [
            {
                path: "/travel-news/:countryName?/:headline?",
                component: () => import('@/views/details/index.vue'),
                meta: {
                    tab: "Discover",
                    title: "Discover hot travel news"
                },
            },
            {
                path: "/hot-topic/:countryName?/:headline?",
                component: () => import('@/views/details/index.vue'),
                meta: {
                    tab: "Hot",
                    title: "Discover "
                },
            },
            {
                path: "/hot-news/:countryName?/:headline?",
                component: () => import('@/views/details/index.vue'),
                meta: {
                    tab: "Hot",
                    title: "Discover hot news"
                },
            }
        ]
    },

    {
        path: "/travel-country/:countryName?",
        name: 'travel country',
        component: () => import('@/views/country/index.vue'),
        meta: {
            tab: "Hot",
        },
    },
    {
        path: "/country/:countryName?",
        name: 'travel country',
        component: () => import('@/views/country/index.vue'),
        meta: {
            tab: "Hot",
        },
    },

    {
        path: "/travel-attractions/:countryName?/:attractionName?",
        name: 'scenic Spot',
        // component: () => import('@/views/scenicspot/index.vue'),
        component: () => import('@/views/scenicSpot/index.vue'),
        meta: {
            tab: "Hot",
        },
    },

    {
        path: "/hot-travel-topic/:articletypeName?/list",
        name: 'HotTopPicList',
        component: () => import('@/views/blog/hotTopPicList.vue'),
        meta: {
            tab: "Hot",
            title: "Discover"
        }
    },

    {
        path: "/hot-travel-topic/:countryName?/:articletypeName?/detail",
        name: 'HotTopPicDetail',
        component: () => import('@/views/details/index.vue'),
        meta: {
            tab: "Hot",
            title: "Discover"
        }
    },

    {
      path: '/News-This-Week',
      name: 'NewsWeek',
      component: () => import('@/views/newsWeek/index.vue'),
      meta: {
        tab: "News",
        title: "News This Week"
      }
    },

    {
      path: "/hot-travel-faq",
      name: "HotFaq",
      component: () => import("@/views/faq/index.vue"),
      meta: {
        tab: "FAQ",
        title: "Hot Travel faq"
      }
    },

    {
      path: "/hot-travel-faq-detail/:countryName?/:headline?",
      name: "HotFaqDetail",
      component: () => import("@/views/faq/detail.vue"),
      meta: {
        tab: "FAQ",
        title: "Hot Travel faq Detail"
      }
    },

    {
        path: "/",
        name: "index",
        component: Blog,
        children: [
            {
                path: "/",
                component: NewArtcleList,
                name: "NewArtcleList",
                meta: {
                    tab: "Hot"
                }
            },
            {
                path: "/ArticleList",
                component: ArticleList,
                name: "ArticleList"
            },
            {
                path: "/groups",
                component: CricleIndex,
                name: "CricleIndex",
                meta: {
                    tab: "Groups",
                }
            },
            {
                path: "/groupDetail/:groupName",
                component: GroupDetail,
                name: "groupDetail",
                meta: {
                    tab: "Groups",
                }
            },
            {
                path: "/us/articleDetail",
                component: ArticleDetail,
                name: "ArticleDetail"
            },
        ]
    },
    {
        name: "hot-countries",
        path: "/hot-countries",
        component: List,

    },
    {
        name: "hot-cities",
        path: "/hot-cities",
        component: List,
    },
    {
        name: "scenes",
        path: "/scenes",
        component: Scenes,
    },
    {
        path: "/asia/countries",
        name: "asiaCountries",
        // component:Countries
    },
    {
        path: "/countries",
        name: "Countries",
        // component:Countries
    },
    {
        path: "/scene",
        name: "scene",
        component: Scene
    },

    {
        path: "/config",
        name: "config",
        component: config,
        children: tabs

    },
    {
        path: "/travel",
        component: Headline,
        // children: [
        //     {
        //         path: '/asia/:country/:city',
        //         // component: CityPage,
        //         props: true
        //     },
        //     {
        //         path: '/asia/:country/:city/:attraction',
        //         // component: AttractionPage,
        //         props: true
        //     }
        // ]

    },
    {
        path: "/flight",
        children: [

        ]
    },
    {
        path: "/flight",
        children: [

        ]
    },
    {
        name: "login",
        path: "/login",
        component: Login
    },
    {
        name: "editor",
        path: "/editor",
        component: Editor
    },
    //等级规则
    {
        path: "/signup",
        name: "signup",
        component: () => import('@/views/user/signup.vue')
    },
    {
        name: "articleSection",
        path: "/:headline",
        component: Headline
    }, {
        path: "/asia-travel/:articleType/:headline",
    }, {
        path: "/travel/:type/:country/:city",
        component: Headline
    },
    {
        path: "/mine",
        name: "mine",
        component: () => import('@/views/mine/index.vue'),
        children: [
            {
                path: "home",
                mame: 'home',
                component: () => import('@/views/mine/pages/home/index.vue'),
            },
            {
                path: "settings",
                name: 'settings',
                component: () => import('@/views/mine/pages/settings/index.vue'),
            },
        ]
    },
    {
        path: "/signin",
        name: "signin",
        component: () => import('@/views/signin/index.vue'),
        children: [
            {
                path: "signin",
                mame: 'signin',
                component: () => import('@/views/signin/pages/signin/index.vue'),
            },
            {
                path: "growth",
                name: 'growth',
                component: () => import('@/views/signin/pages/growth/index.vue'),
            }
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    //记录从哪个页面跳转登录页面
    if (to.path === '/login') {
        if (from.path && from.path !== '/signup') {
            sessionStorage.setItem('redirect', from.path)
        }
    }

    //  修改title 
    if (to.params) {
        const { countryName, cityName, tripTypeName, headline, subtitle } = to.params;
        // if(subtitle) document.title=subtitle;
        if(!to.path.includes("travel-country")){

       
        countryName ? (document.title = (cityName ? "where to go in " + countryName + ", travel to " + cityName + ", " : " ") + to.meta.title + (countryName ? " " + countryName : " ")) : ""
        if(headline){
            document.title=document.title+" : "+headline
        }
    }
       
    }
    next()
})

export default router