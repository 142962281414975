<template>
  <div class="mainContentMiddleLeft">
    <div class="mainContentMiddleLeftCarousel">
      <b-carousel class="carouselContainer" :indicator="false" :autoplay="autoplay">
        <!-- <b-carousel-item style="cursor: pointer">
          <div class="carouselItemContentImg">
            <img src="@/assets/img/1.jpg" @load="handleCarouselOnLoad" />
          </div>
          <a class="carouselTitle" :href="openHotTopic({
            title: 'Explore Popular Countries',
            articleTypeId: '389',
          })" target="_blank">
            <div class="carouselItemContentMiddle lx-text-fff lx-text-16 lx-text-fontWeight-500">
              Explore Popular Countries
            </div>
            <div class="carouselItemContentFooter">
              <div class="lx-text-16 lx-text-ffffff99 lx-text-fontWeight-500 footerContent">
                Dive into a curated list of top countries to visit and uncover
                the hidden gems each destination has to offer.
              </div>
            </div>
          </a>
        </b-carousel-item> -->
        <b-carousel-item v-for="(desc, index) in destinations" :key="index" style="cursor: pointer">
          <div class="carouselItemContentImg">
            <img :src="desc.img" />
          </div>
          <a class="carouselTitle" :href="openHotTopic(desc)" target="_blank">
            <div class="carouselItemContentMiddle lx-text-fff lx-text-18 lx-text-fontWeight-500">
              {{ desc.title }}
            </div>
            <div class="carouselItemContentFooter">
              <div class="lx-text-16 lx-text-ffffff99 lx-text-fontWeight-500 footerContent">
                {{ desc.description }}
              </div>
            </div>
          </a>
        </b-carousel-item>
      </b-carousel>
    </div>
  </div>
</template>

<script>
import { replaceSpacesWithDash } from "@/utils/utils";
export default {
  name: "Carousel",
  data() {
    return {
      autoplay: true,
      destinations: [
        {
          img: require("@/assets/img/2.jpg"),
          title: "Trending Travel Insights",
          articleTypeId: "391",
          subtitle: [
            "Discover exciting travel guides, adventure stories, and local insights.",
            "Browse stories tailored to your destination with exclusive ads for travel deals.",
          ],
          description:
            "Stay with the latest and most engaging travel stories with unique insights and inspiration for your next journey.",
        },
        {
          img: require("@/assets/img/3.jpg"),
          title: "Top Attractions to Visit",
          articleTypeId: "394",
          subtitle: [
            "From the Great Wall of China to Angkor Wat in Cambodia, find out which attractions you can't miss.",
            "Check out curated content, sponsored ads, and deals on guided tours to these world-famous spots.",
          ],
          description:
            "Explore must-see attractions around the world, with recommendations and tips for making the most of your visit.",
        },
        {
          img: require("@/assets/img/4.jpg"),
          title: "Must-Try Food & Drink",
          articleTypeId: "393",
          subtitle: [
            "Embark on a culinary adventure with our guide to the best restaurants, street food, and bars.",
            "From sushi in Japan to Pad Thai in Thailand, discover where to eat and drink on your travels.",
          ],
          description:
            "comprehensive and best local cuisine and dining experiences, even street food and bars",
        },
        {
          img: require("@/assets/img/5.jpg"),
          title: "Essential Travel Guides",
          articleTypeId: "390",
          subtitle: [
            "Prepare for your trip with in-depth travel guides covering the best routes, safety tips, and more.",
            "From first-time travelers to seasoned adventurers, our guides offer something for everyone.",
          ],
          description:
            "Get the essential information you need to plan your trip effectively, expert advice and detailed itineraries.",
        },
        {
          img: require("@/assets/img/6.jpg"),
          title: "Cheap Flights and Hotels",
          articleTypeId: "392",
          subtitle: [
            "Find unbeatable deals on flights and accommodations for your next adventure.",
            "Browse through our partners' exclusive offers and save on your next trip.",
          ],
          description:
            "Discover the best available travel deals, discounted flights and accommodations ",
        },
      ],
    };
  },
  methods: {
    handleCarouselOnLoad() {
      this.destinations = [
        {
          img: require("@/assets/img/2.jpg"),
          title: "Trending Travel Insights",
          articleTypeId: "391",
          subtitle: [
            "Discover exciting travel guides, adventure stories, and local insights.",
            "Browse stories tailored to your destination with exclusive ads for travel deals.",
          ],
          description:
            "Stay with the latest and most engaging travel stories with unique insights and inspiration for your next journey.",
        },
        {
          img: require("@/assets/img/3.jpg"),
          title: "Top Attractions to Visit",
          articleTypeId: "394",
          subtitle: [
            "From the Great Wall of China to Angkor Wat in Cambodia, find out which attractions you can't miss.",
            "Check out curated content, sponsored ads, and deals on guided tours to these world-famous spots.",
          ],
          description:
            "Explore must-see attractions around the world, with recommendations and tips for making the most of your visit.",
        },
        {
          img: require("@/assets/img/4.jpg"),
          title: "Must-Try Food & Drink",
          articleTypeId: "393",
          subtitle: [
            "Embark on a culinary adventure with our guide to the best restaurants, street food, and bars.",
            "From sushi in Japan to Pad Thai in Thailand, discover where to eat and drink on your travels.",
          ],
          description:
            "comprehensive and best local cuisine and dining experiences, even street food and bars",
        },
        {
          img: require("@/assets/img/5.jpg"),
          title: "Essential Travel Guides",
          articleTypeId: "390",
          subtitle: [
            "Prepare for your trip with in-depth travel guides covering the best routes, safety tips, and more.",
            "From first-time travelers to seasoned adventurers, our guides offer something for everyone.",
          ],
          description:
            "Get the essential information you need to plan your trip effectively, expert advice and detailed itineraries.",
        },
        {
          img: require("@/assets/img/6.jpg"),
          title: "Cheap Flights and Hotels",
          articleTypeId: "392",
          subtitle: [
            "Find unbeatable deals on flights and accommodations for your next adventure.",
            "Browse through our partners' exclusive offers and save on your next trip.",
          ],
          description:
            "Discover the best available travel deals, discounted flights and accommodations ",
        },
      ];
    },
    //打开详情页
    openHotTopic(item) {
      let path = `/hot-travel-topic/${replaceSpacesWithDash(item.title)}/list`;
      console.log(item.img)
      let query = {
        articleTypeId: item.articleTypeId,
        subtitle: replaceSpacesWithDash(item.title),
        // src: JSON.stringify(item.img)
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      return routeData.href;
    },
  },
  beforeCreate(){
    // this.handleCarouselOnLoad()
  }
};
</script>

<style lang="scss" scoped>
.mainContentMiddleLeft {
  display: flex;
  flex-direction: column;
  width: 32%;
  padding-right: .5rem;
  background: #f7f8fc;

  .mainContentMiddleLeftCarousel {
    height: 24.9rem;
    width: 100%;
    overflow: hidden;

    .carouselContainer {
      width: 100%;
      height: 100%;

      ::v-deep .carousel-items {
        width: 100% !important;
        height: 100% !important;

        .carousel-item {
          width: 100%;
          height: 100%;
          position: relative;

          .carouselTitle {
            transition: all 0.9s ease-in-out;
            position: absolute;
            background: radial-gradient(ellipse at center,
                rgba(3, 3, 3, 0.15) 50% 40%,
                rgba(3, 3, 3, 0.13) 60% 40%,
                rgba(3, 3, 3, 0.09) 100%,
                rgba(3, 3, 3, 0.08) 100%);
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            padding: 2.5rem 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          .carouselTitle:hover {
            color: #fff;
            background: radial-gradient(ellipse at center,
                rgba(3, 3, 3, 0.28) 50% 40%,
                rgba(3, 3, 3, 0.3) 60% 40%,
                rgba(3, 3, 3, 0.42) 100%);
          }

          .carouselItemContentImg {
            width: 100%;
            height: 100%;

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .carouselItemContentMiddle {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          .carouselItemContentFooter {
            box-sizing: border-box;
            padding: 0.5rem 1.25rem;
            margin-top: .5rem;
            display: flex;
            align-items: center;

            .footerContent {
              text-align: center;
            }
          }

          .carouselItemContentButton {
            width: 8rem;
          }

          .carouselItemContent {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .desc {
              font-size: 1rem;
              padding: 1rem 2.5rem;
              color: #fff;
              font-weight: 100;
              text-align: center;
              transition: font-weight 1s ease;
            }
          }
        }
      }
    }
  }
}
</style>