import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import store from './store/index.js';
Vue.config.productionTip = false


import './assets/css/materialdesignicons.min.css'
import "./assets/css/all.css"


import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy)


import Vuecheckin from 'vue-checkin'
Vue.use(Vuecheckin)


import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);


new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

