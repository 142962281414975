
import ky from 'ky'
const base = process.env.NODE_ENV == 'development' ? "/api" : "https://www.leisuretripinasia.com";
import { ToastProgrammatic as Toast } from 'buefy'
var postData = async (path, config) => {
   let token = localStorage.getItem("crm-token");

   let data = await ky.post(base + path, {
      headers: {
         "apiToken": token
      },
      ...config
   }).json();
   // console.log(data,33)
   if(data.msg!='success'){
    if (data.retCode == 403) {
      Toast.open({
        message: data.msg,
        type: "is-warning",
      });
    }
    return data.msg
   }else{
      return data.data
   }
  

};
export default postData;