<template>
  <section class="section">
    <div class="container">
      <div class="content has-text-centered mb-6">
        <h1 class="title is-3">Discover Asia's Hidden Gems</h1>
       
      </div>
      <div class="content has-text-centered mb-6">
       
        <h2 class="subtitle is-4">Unveil the secrets of Asia's lesser-known attractions</h2>
      </div>

      <div class="content has-text-centered mb-6">
        <h2 class="title is-3">Why Choose Us for Your Asian Adventure?</h2>
        <h3 class="subtitle is-5">Experience the best of Asia with our expertly curated guides and insights.</h3>
      </div>

      <div class="columns is-desktop mb-6">
        <div class="column is-4" v-for="benefit in benefits" :key="benefit.title">
          <div class="box has-text-centered">
            <h3 class="title is-5">{{ benefit.title }}</h3>
            <p>{{ benefit.description }}</p>
          </div>
        </div>
      </div>

      <h2 class="title is-3 has-text-centered">Explore Our Top Attractions</h2>
      <div class="columns is-multiline">
        <div class="column is-4-desktop is-6-tablet" v-for="spot in attractions" :key="spot.name">
          <div class="card">
            <div class="card-image">
              <figure class="image is-4by3">
                <img :src="spot.image" :alt="spot.name" />
              </figure>
            </div>
            <div class="card-content">
              <h3 class="title is-5">{{ spot.name }}</h3>
              <p class="subtitle is-6">{{ spot.description }}</p>
              <p class="has-text-weight-semibold">Why Visit: {{ spot.benefits }}</p>
              <a :href="'https://leisuretripinasia.com'+spot.link" class="button is-link is-fullwidth">Learn More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      benefits: [
        {
          title: "In-Depth Insights",
          description: "We provide detailed descriptions of lesser-known attractions, ensuring you make informed choices about your travel destinations."
        },
        {
          title: "Curated Experiences",
          description: "Our team curates unique itineraries that highlight hidden gems, allowing you to experience Asia beyond the typical tourist spots."
        },
        // {
        //   title: "Local Connections",
        //   description: "We connect you with local guides and communities, offering authentic experiences that reflect the true culture of each destination."
        // },
        // {
        //   title: "Exclusive Offers",
        //   description: "Gain access to exclusive deals on entry fees, guided tours, and accommodation that are not available elsewhere."
        // },
        {
          title: "Sustainable Travel",
          description: "By promoting lesser-known sites, we encourage sustainable tourism, helping preserve the environment and local cultures."
        },
        // {
        //   title: "Expert Recommendations",
        //   description: "Benefit from insights from seasoned travelers and locals who share their favorite off-the-beaten-path attractions."
        // }
      ],
      attractions: [
        {
          name: "Sapa Terraces, Vietnam",
          description: "Known for its breathtaking rice terraces and vibrant local cultures.",
          benefits: "Perfect for hiking and immersing yourself in indigenous cultures.",
          image: "https://example.com/sapa.jpg",
          link: "https://example.com/sapa"
        },
        {
          name: "Nubra Valley, India",
          description: "Famous for its sand dunes, Bactrian camels, and stunning landscapes.",
          benefits: "A unique blend of adventure and tranquility.",
          image: "https://example.com/nubra.jpg",
          link: "https://example.com/nubra"
        },
        {
          name: "Mount Rinjani, Indonesia",
          description: "Home to Indonesia's second-highest volcano, offering stunning trekking routes.",
          benefits: "An adventure for nature lovers and thrill-seekers.",
          image: "https://example.com/rinjani.jpg",
          link: "https://example.com/rinjani"
        },
        {
          name: "Gjirokastër, Albania",
          description: "A UNESCO World Heritage site with well-preserved Ottoman architecture.",
          benefits: "A historical gem that offers a glimpse into the past.",
          image: "https://example.com/gjirokaster.jpg",
          link: "https://example.com/gjirokaster"
        },
        {
          name: "Huashan Mountain, China",
          description: "Known for its steep cliffs and beautiful hiking trails.",
          benefits: "An unforgettable adventure for the bold.",
          image: "https://example.com/huashan.jpg",
          link: "https://example.com/huashan"
        }
      ]
    };
  }
};
</script>

<style scoped>
.section {
  padding: 3rem 1.5rem;
}

.box {
  margin-bottom: 1.5rem;
  padding: 1.5rem;
}

.card {
  margin-bottom: 1.5rem;
  overflow: hidden; /* Prevent content overflow */
  border-radius: 10px; /* Rounded corners for card */
}

.card-image img {
  max-width: 100%; /* Ensure image fits within card */
  height: auto; /* Maintain aspect ratio */
}

h1, h2 {
  color: #363636;
}

.button {
  margin-top: 1rem;
}

.has-text-centered {
  text-align: center;
}

.mb-6 {
  margin-bottom: 6rem;
}

.image {
  object-fit: cover;
}
</style>
