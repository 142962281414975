<template>
    <div class="tags">
        <div class="wrapper">
            <p class="tagTitle">Selected condition</p>
            <b-tag type="is-warning" v-for="item, index in tags" :key="index" aria-close-label="Close tag"
                style="height: 25px;margin-top: 2px;margin-bottom: 2px;" closeType='is-warning' closable
                @close="closeTag(item, index)" size="is-medium">
                <span style="color: #222;line-height: 16px;">{{ item.value }}</span>
            </b-tag>
        </div>
        <div class="right">
            <!-- <b-button @click="reset" size="is-small">Reset</b-button> -->
            <div @click="reset">Reset</div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        state() {
            return this.$store.state.discover.state
        },
        tags() {
            return this.$store.state.discover.tags
        }
    },
    watch: {
        state: {
            handler: function (nv, ov) {
                this.setTags(nv)
            },
            deep: true
        }
    },
    data() {
        return {

        }
    },
    methods: {
        //标签
        setTags(obj) {
            this.$store.commit("resetTags");
            if (obj.countryName) {
                this.tags.push({
                    id: obj.country,
                    value: obj.countryName,
                    idkey: 'country',
                    valuekey: 'countryName'
                })
            }
            if (obj.cityName) {
                this.tags.push({
                    id: obj.city,
                    value: obj.cityName,
                    idkey: 'city',
                    valuekey: 'cityName'
                })
            }
            if (obj.triptypeName) {
                this.tags.push({
                    id: obj.triptype,
                    value: obj.triptypeName,
                    idkey: 'triptype',
                    valuekey: 'triptypeName'
                })
            }
            if (obj.articleTypeId) {
                this.tags.push({
                    id: obj.articleTypeId,
                    value: obj.articleTypeName,
                    idkey: 'articleTypeId',
                    valuekey: 'articleTypeName'
                })
            }
        },
        closeTag(item) {
            if (item.idkey === 'country') {
                this.state.city = ''
                this.state.cityName = ''
                this.$store.commit("setHotCities", []);
            }
            this.state[item.idkey] = ''
            this.state[item.valuekey] = ''
        },
        reset() {
            this.$store.commit("resetTags");
            this.$store.commit("setHotCities", []);
            this.$store.commit("resetState");
        }
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {
        this.setTags(this.state)
    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {

    }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.tags {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 4px;
    padding: 16px 13px;
    margin-top: 10px;

    .wrapper {
        display: flex;
        align-items: center;

        .tagTitle {
            font-size: 10px;
            color: #999999;
            line-height: 14px;
            line-height: 27px;
            margin-right: 16px;
        }
    }

    .right {
        div {
            width: 63px;
            height: 25px;
            background: rgba(255, 255, 255, 0.39);
            border: 1px solid #222222;
            font-weight: 500;
            font-size: 10px;
            color: #222222;
            line-height: 23px;
            text-align: center;
            cursor: pointer;
            border-radius: 2px;
        }
    }
}
</style>