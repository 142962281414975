<template>
  <div class="mainContentNews">
    <common-title common-title-name="Hot News" @clickPage="handleGoPageMore"></common-title>
    <ul class="mainContentNewsList width6">
      <li v-for="(item, index) in hotNewsAllList" :key="index">
        <div class="newsHeader">
          <a :href="'https://leisuretripinasia.com'+openHotCountry(item)" target="_blank">
            <h2 class="lx-text-14 lx-text-99">{{ item.country }}</h2>
            <p class="lx-text-14 lx-text-99">{{ item.total }} Items</p>
          </a>
        </div>
        <div class="listContent" v-for="(curr, currIndex) in item.list" :key="currIndex">
          <div v-if="currIndex == 0" class="newsPic" @click="openHotNews(curr, true)">
            <img :src="curr.mediaLink" alt="" @error="onImageError" />
          </div>
          <div class="newsTitle">
            <h3>
              <a :href="'https://leisuretripinasia.com'+openHotNews(curr)" target="_blank" class="lx-text-14 lx-text-666 lx-text-ell-3">{{ curr.headline
                }}</a>
            </h3>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import postData from "../../../common/cHttp";
import commonTitle from "./commonTitle.vue";
import { timestampToDateString, replaceSpacesWithDash } from "@/utils/utils";
export default {
  name: "HotNews",
  components: {
    commonTitle,
  },
  data() {
    return {
      articlePath: "/api/t/article/homeList",
      hotNewsAllList: [
        
      ],
    };
  },
  created() {
    setTimeout(()=>{
      this.getHotCountryNews();
    },0)
    
  },
  methods: {
    handleGoPageMore() {
      let path = `/discover/news`;
      let query = {
        articleTypeId: "332",
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      window.open(routeData.href, "_blank");
    },
    async getHotCountryNews() {
      const list = [
        {
          name: "China",
          id: "285",
          
          
        },
        {
          id: "18",
          name: "Indonesia",
          
        },
        {
          id: "305",
          name: "Japan",
          
        },
        {
          id: "15",
          name: "Korea",
          
        },
        {
          id: "14",
          name: "Thailand",
         
        },
        {
          id: "16",
          name: "Vietnam",
          
        },
      ];
      // const evenIndexedArray = [];
      // const oddIndexedArray = [];
      const newList = [];
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        let data = await postData(this.articlePath, {
          json: {
            countryId: item.id,
            articleTypeId: "332",
            pageNo: 1,
            pageSize: 3,
          },
        });
        if (data && data.records && data.records.length) {
          let contentObj = {
            country: item.name,
            countryId: item.id,
            total: data.total,
            list: [],
          };
          data.records.forEach((element) => {
            const obj = element;
            contentObj.list.push({
              headline: obj.headline,
              id: obj.id,
              mediaLink: obj.source.mediaLink,
              name: obj.source.name,
              total: data.total,
              num: obj.destCity,
              createTime: timestampToDateString(obj.createTime, "mm-dd"),
              country: item.name,
              countryId: item.id,
              username: item.username,
              des: item.des,
              rateList: item.rateList,
              rate: item.rate,
            });
          });
          newList.push(contentObj);
          this.hotNewsAllList.push(contentObj)
        }
      }
      // console.log(JSON.stringify(newList));
      this.hotNewsAllList = newList;
      // console.log(JSON.stringify(this.hotNewsAllList));
    },
    onImageError(event) {
      event.target.src = require("@/assets/img/default.png");
    },
    // openHotCountry(item) {
    //   let path = `/discover/${replaceSpacesWithDash(item.country)}/news`;
    //   let query = {
    //     articleTypeId: "332",
    //     country: item.countryId,
    //     peo: item.country.charAt(0),
    //   };
    //   let routeData = this.$router.resolve({
    //     path: path, // path 要跳转的路由地址
    //     query: query,
    //   });
    //   return routeData.href;
    // },
    openHotCountry(item) {
      let path = `/travel-country/`+item.country;
      let query = {
        countryId: item.countryId,
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      return routeData.href;
    },
    openHotNews(item, flag) {
      let path = `/hot-news/${replaceSpacesWithDash(item.headline)}`;
      if (item.country) {
        path = `/hot-news/${item.country}/${replaceSpacesWithDash(
          item.headline
        )}`;
      }
      let query = {
        id: item.id,
        img: encodeURIComponent(item.mediaLink),
      };
      let routeData = this.$router.resolve({
        path: path, // path 要跳转的路由地址
        query: query,
      });
      if (flag) {
        window.open(routeData.href, "_blank");
      }
      return routeData.href;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("@/assets/css/blog.scss");

.mainContentNewsList {
  li {
    background: transparent !important;
    .listContent {
      &:last-child {
        .newsTitle {
          border-bottom: 1px solid transparent;
        }
      }
    }
  }
}

.listContent {
  margin-bottom: 0.5rem;

  .newsTitle {
    min-height: 7rem;
    max-height: 7rem;
    background: #fff !important;
    border-bottom: 1px solid #ddd;
  }
}
</style>