<template>
  <section class="section">
    <div class="container">
      <h1 class="title has-text-centered">Explore the Wonders of Asia</h1>

      <div class="columns is-multiline">
        <div class="column is-12">
          <h2 class="subtitle has-text-centered">Discover Amazing Countries</h2>
          <h3 class="has-text-centered highlight">Experience Unique Cultures and Breathtaking Landscapes</h3>
        </div>

        <div class="column is-4" v-for="country in countries" :key="country.name">
          <div class="card">
            <div class="card-image">
              <figure class="image">
                <img :src="country.image" :alt="'leisuretripinasia.com'+country.name" />
              </figure>
            </div>
            <div class="card-content">
              <h3 class="title is-4">{{ country.name }}</h3>
              <p>{{ country.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="columns is-multiline mt-5">
        <div class="column is-12">
          <h2 class="subtitle has-text-centered">Must-Visit Cities</h2>
          <h3 class="has-text-centered highlight">Urban Adventures Await in These Iconic Destinations</h3>
        </div>

        <div class="column is-4" v-for="city in cities" :key="city.name">
          <div class="card">
            <div class="card-image">
              <figure class="image">
                <img :src="city.image" :alt="'leisuretripinasia.com'+city.name" />
              </figure>
            </div>
            <div class="card-content">
              <h3 class="title is-4">{{ city.name }}</h3>
              <p>{{ city.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="columns is-multiline mt-5">
        <div class="column is-12">
          <h2 class="subtitle has-text-centered">Top Attractions to Visit</h2>
          <h3 class="has-text-centered highlight">Unforgettable Experiences Await at These Iconic Sites</h3>
        </div>

        <div class="column is-4" v-for="attraction in attractions" :key="attraction.name">
          <div class="card">
            <div class="card-image">
              <figure class="image">
                <img :src="attraction.image" :alt="'leisuretripinasia.com'+attraction.name" />
              </figure>
            </div>
            <div class="card-content">
              <h3 class="title is-4">{{ attraction.name }}</h3>
              <p>{{ attraction.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="columns mt-5">
        <div class="column is-12 has-text-centered">
          <b-button type="is-primary" @click="exploreMore">Plan Your Adventure Now!</b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      countries: [
        { name: "Japan", image: "https://images.unsplash.com/photo-1526481280693-3bfa7568e0f3?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", description: "Experience a perfect blend of tradition and modernity." },
        { name: "Thailand", image: "https://images.unsplash.com/photo-1534008897995-27a23e859048?fm=jpg&amp;q=60&amp;w=3000&amp", description: "Discover stunning beaches and vibrant culture." },
        { name: "India", image: "https://images.unsplash.com/photo-1524492412937-b28074a5d7da?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", description: "Explore rich heritage and diverse landscapes." },
      ],
      cities: [
        { name: "Tokyo", image: "https://example.com/tokyo.jpg", description: "A city like no other, blending tradition with innovation." },
        { name: "Bali", image: "https://example.com/bali.jpg", description: "Relax in a tropical paradise with stunning scenery." },
        { name: "Singapore", image: "https://images.unsplash.com/photo-1508964942454-1a56651d54ac?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", description: "A city of dreams with futuristic architecture." },
      ],
      attractions: [
        { name: "Great Wall of China", image: "https://example.com/great-wall.jpg", description: "A marvel of ancient architecture." },
        { name: "Angkor Wat", image: "https://example.com/angkor-wat.jpg", description: "The largest religious monument in the world." },
        { name: "Ha Long Bay", image: "https://example.com/ha-long-bay.jpg", description: "Famous for emerald waters and towering islands." },
      ],
    };
  },
  methods: {
    exploreMore() {
      window.location.href = '/destinations'; // Redirect to a detailed destinations page
    }
  }
};
</script>

<style scoped>
.section {
  padding: 3rem 1.5rem;
}

.card {
  margin-bottom: 1rem;
}

h1, h2, h3 {
  color: #363636;
}

.subtitle {
  margin-top: 1.5rem;
  font-weight: bold;
}

h3 {
  color: #4a4a4a;
  margin-top: 0.5rem;
}

.highlight {
  font-weight: bold;
  color: #3273dc; /* Primary color */
  transition: color 0.3s ease;
}

.highlight:hover {
  color: #ff3860; /* Change color on hover */
}
</style>
