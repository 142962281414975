//字符串首字母改为大写
export const convertInitial = (str) => {
    str = str.replace(str[0], str[0].toUpperCase());
    return str
}

// 将时间戳转换为日期对象
export const timestampToDateString = (timestamp, format) => {
    var date = new Date();
    if (timestamp) {
        date = new Date(timestamp);
    }
    var year = date.getFullYear(); // 获取年份
    var month = (date.getMonth() + 1).toString().padStart(2, "0"); // 获取月份并补零
    var day = date.getDate().toString().padStart(2, "0"); // 获取日期并补零
    switch (format) {
        case "yyyy-mm-dd":
            return `${year.toString()}-${month}-${day}`;
        case "yyyy/mm/dd":
            return `${year.toString()}/${month}/${day}`;
        case "yyyy年mm月dd日":
            return `${year.toString()}年${month}月${day}日`;
        case "mm-dd":
            return `${month}-${day}`
        default:
            return `${year.toString()}-${month}-${day}`;
    }
}

//将时间戳转换为英文日期格式
export const timestampToEnDateString = (timestamp) => {
    var date = new Date();
    if (timestamp) {
        date = new Date(timestamp);
    }
    let options = { year: 'numeric', month: 'short', day: 'numeric' };
    let englishDate = date.toLocaleDateString('en-US', options);
    return englishDate
}

//把空格替换成-
export const replaceSpacesWithDash = (str) => {
    str = str.trim()

    // 第一步：先替换空格和&为-
    let result = str.replace(/[\s&]+/g, '-');

    // 第二步：将逗号或冒号后面紧跟空格的逗号或冒号替换为-
    result = result.replace(/([,:])\s+/g, '-');

    // 第三步：将其他符号替换为-
    result = result.replace(/[.!?;(){}\[\]"']+/g, '-');  // 替换常见的符号为-

    // 第四步：将多个连续的-合并为一个-
    result = result.replace(/-+/g, '-');

    // 第五步：将连续的//替换为单个/
    result = result.replace(/\/+/g, '/');

    return result;
}

//把-替换为空格
export const replaceWithDashSpaces = (str) => {
    return str.replace(/[-]/g, ' ');
}

//将日期改为英文格式