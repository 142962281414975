<template>
    <div class="searchResult">
        <div class="searchResult-title">
            <span>100000+</span>
            <span> search items</span>
            <span> ordered default by popularity</span>
        </div>
        <ul class="mainContentNewsList" v-infinite-scroll="loadMore" :infinite-scroll-disabled="dataLoadStop"
            :infinite-scroll-distance="50">
            <ArticleItem class="articleItem" v-for="(item, index) in result" :key="index" :info="item"></ArticleItem>
        </ul>
    </div>
</template>

<script>
import ArticleItem from '@/components/articleItem.vue'
import { getArticleList } from '@/api/discover'
export default {
    components: {
        ArticleItem
    },
    computed: {
        state() {
            return this.$store.state.discover.state
        }
    },
    watch: {
        state: {
            handler: function (nv, ov) {
                this.dataLoadStop = false
                this.result = []
                this.pageNo = 1
                this.getArticleList()
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            result: [],
            pageNo: 1,
            pageSize: 10,
            total: 0,
            dataLoadStop: false,
        }
    },
    methods: {
        async getArticleList() {
            let result = await getArticleList({
                countryId: this.state.country,
                cityId: this.state.city,
                tripTypeId: this.state.triptype,
                articleTypeId: this.state.articleTypeId,
                pageNo: this.pageNo,
                pageSize: this.pageSize,
            })
            this.result = this.result.concat(result.records)
            this.total = result.total
            if (this.result.length >= this.total || !result.records.length) {
                this.dataLoadStop = true
            }
        },
        loadMore() {
            if (this.dataLoadStop) {
                return
            }
            this.pageNo += 1
            this.getArticleList()
        },
    }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.searchResult {
    padding: 0 20px 20px;
    border-radius: 4px;
    margin-top: 20px;
    padding-left: 40px;

    .searchResult-title {
        span {
            font-size: 14px;

            &:nth-child(1) {
                color: red;
            }

            &:nth-child(2) {
                color: #000;
            }

            &:nth-child(3) {
                color: gray;
                margin-left: 10px;
            }
        }
    }

    .mainContentNewsList {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        min-height: 530px;

        .articleItem {
            &:nth-child(5n) {
                margin-right: 0;
            }
        }
    }
}
</style>